import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import "../Loan.css";
import socketService from "../../../app/api/ws/initializeWebSocketService";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "../../../app/api/auth/authSlice";
import { useAddPaymentMutation } from "../../../app/api/loans/loanApiSlice";
import { CustomSnackbar } from "../../../components/popover/Snackbar";

const PaymentFormv1 = ({
  data,
  onClose,
  setSnackBarMsg,
  setSnackBarColor,
  setPopoverOpen,
}) => {
  const loanInfo = data.computed;
  console.log(loanInfo);
  const [checkedState, setCheckedState] = useState({
    updateInterest: false,
    excludePayment: false,
    updateMonth: false,
  });
  const [paymentAmount, setAmount] = useState(loanInfo.monthlyPayment);
  const [cardAmount, setCardAmount] = useState(0);
  const [paymentDate, setPaymentDate] = useState(
    dayjs(loanInfo.effectived_date)
  );
  const [newInterest, setNewInterest] = useState(loanInfo.loanInterest);
  const [interestAmount, setInterestAmount] = useState(0);
  const [remaningBalance, setRemainingBalance] = useState(0);
  const [changeValue, setChangeValue] = useState(0);
  const [newTerms, setNewTerms] = useState(parseInt(loanInfo.loanTerm));

  const user_id = useSelector(selectCurrentUserId);
  const [addPayment, { isLoading }] = useAddPaymentMutation();

  useEffect(() => {
    if (!checkedState.updateInterest) {
      setNewInterest(loanInfo.loanInterest);
    }
  }, [checkedState.updateInterest, loanInfo.loanInterest]);

  const handleIncludePayment = (event) => {
    const { name, checked } = event.target;
    setCheckedState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    let interestAmount = 0;
    let remaningBalance = 0;
    let changeValue = 0;

    if (loanInfo.loanType === 1) {
      if (checkedState.excludePayment) {
        interestAmount = loanInfo.loanAmount * (newInterest / 100) * newTerms;
        remaningBalance = loanInfo.loanAmount - paymentAmount;
        changeValue = 0;
      } else {
        interestAmount = loanInfo.loanAmount * (newInterest / 100) * newTerms;
        if (checkedState.updateInterest) {
          remaningBalance =
            loanInfo.loanAmount + interestAmount - paymentAmount;
        } else {
          remaningBalance = loanInfo.remaining_balance - paymentAmount;
        }
        changeValue = paymentAmount - interestAmount;
      }
    } else if (loanInfo.loanType === 2) {
      interestAmount = loanInfo.monthlyPayment * newTerms;
      remaningBalance = loanInfo.remaining_balance - paymentAmount;
      changeValue = paymentAmount - interestAmount;
    } else {
      interestAmount = loanInfo.monthlyPayment;
      remaningBalance = loanInfo.remaining_balance - paymentAmount;
      changeValue = paymentAmount - interestAmount;
    }
    setInterestAmount(interestAmount);
    setRemainingBalance(remaningBalance);
    setChangeValue(changeValue);
  }, [
    checkedState,
    loanInfo,
    newInterest,
    paymentAmount,
    changeValue,
    newTerms,
  ]);

  const handleCardAmountChange = (e) => {
    const value = e.target.value;
    // Allow only numeric input including decimals
    const numericValue = value.replace(/[^0-9.]/g, "");
    setCardAmount(numericValue);
  };

  const handleCardAmountBlur = () => {
    // Format the card amount when input loses focus
    setCardAmount(
      parseFloat(cardAmount).toLocaleString("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  const handleCloseSnackbar = () => {
    setPopoverOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const formData = {
        payment_amount: paymentAmount,
        added_by: user_id,
        num_payment: 1,
        loanId: loanInfo.loanId,
        payment_date: paymentDate,
        effectived_date: loanInfo.effectived_date,
        loan_amount: loanInfo.loanAmount,
        loan_interest: newInterest,
        new_interest: newInterest,
        apply_to_interest: checkedState.excludePayment ? 1 : 0,
        update_interest: checkedState.updateInterest ? 1 : 0,
        payment_change: changeValue,
        remaining_balance: remaningBalance,
        cardAmount: cardAmount,
        loan_term: newTerms,
        status: 0,
        type: "payment",
      };
      const res = await addPayment(formData).unwrap();
      if (res) {
        socketService.sendMessage({ type: "refresh" });
        onClose();
        setSnackBarMsg("Successfully added payment");
        setSnackBarColor("success");
        setPopoverOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              mb: 3,
              mt: 2,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                maxHeight: "400px",
                overflowY: "auto",
                width: "100%",
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Stack direction={"column"} spacing={2}>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="paymentRow"
                >
                  <Typography variant="h6">Payment Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="paymentInput"
                      value={paymentDate}
                      onChange={(newValue) => setPaymentDate(newValue)}
                      slotProps={{
                        toolbar: {
                          toolbarPlaceholder: "__",
                          toolbarFormat: "DD / MM / YYYY",
                          hidden: false,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  className="paymentRow"
                >
                  <Typography variant="h6">Principal Amount</Typography>
                  <Typography variant="h6">
                    {" "}
                    ₱
                    {loanInfo.loanAmount.toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  className="paymentRow"
                >
                  <Typography variant="h6">Interest Rate</Typography>
                  <TextField
                    className="paymentInput"
                    label="Interest Rate"
                    type="number"
                    onChange={(e) => setNewInterest(e.target.value)}
                    value={newInterest}
                    disabled={checkedState.updateInterest ? false : true}
                  />
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  className="paymentRow"
                >
                  <Typography variant="h6">Monthly Terms</Typography>
                  <TextField
                    className="paymentInput"
                    label="Monthly Terms"
                    type="number"
                    onChange={(e) => setNewTerms(e.target.value)}
                    value={newTerms}
                    disabled={checkedState.updateMonth ? false : true}
                  />
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  className="paymentRow"
                >
                  <Typography variant="h6">
                    {loanInfo.loanType === 1 || loanInfo.loanType === 2
                      ? "Interest x Months"
                      : "Monthly Payment"}
                  </Typography>
                  <Typography variant="h6">
                    ₱
                    {interestAmount.toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  className="paymentRow"
                >
                  <Typography variant="h6">Total Loan Amount </Typography>
                  <Typography variant="h6">
                    ₱
                    {loanInfo.remaining_balance.toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Stack>
                {/*<Paper elevation={3} sx={{ p: 2, mb: 3, width: "100%" }}>
                  <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                    <Stack
                      spacing={2}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        maxWidth: "470px",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        Card Amount
                      </Typography>
                      <TextField
                        label="CARD AMOUNT"
                        type="text"
                        value={cardAmount}
                        onChange={handleCardAmountChange}
                        onBlur={handleCardAmountBlur}
                        style={{ width: "100%" }}
                      />
                    </Stack>
                  </Stack>
                </Paper>*/}
                <Paper elevation={3} sx={{ p: 2, mb: 3, width: "100%" }}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Stack
                      spacing={2}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        maxWidth: "470px",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        AMOUNT TO PAY
                      </Typography>
                      <TextField
                        label="Payment"
                        type="text"
                        value={paymentAmount.toLocaleString("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        onChange={(e) => setAmount(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </Stack>
                  </Stack>
                </Paper>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  className="paymentRow"
                >
                  <Typography variant="h6">Change</Typography>
                  <Typography variant="h6">
                    ₱
                    {changeValue.toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  className="paymentRow"
                >
                  <Typography variant="h6">Remaining Balance</Typography>
                  <Typography variant="h6">
                    ₱
                    {remaningBalance.toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Stack>
                {loanInfo.loanType === 1 || loanInfo.loanType === 2 ? (
                  <Stack
                    direction={"column"}
                    sx={{ position: "absolute", left: "24px" }}
                  >
                    {loanInfo.loanType === 1 ? (
                      <>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkedState.excludePayment}
                                onChange={handleIncludePayment}
                                name="excludePayment"
                              />
                            }
                            sx={{ width: "min-content", color: "red" }}
                            label="Apply Payment to Principal"
                          />
                        </FormGroup>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                      </>
                    ) : (
                      ""
                    )}

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.updateInterest}
                            onChange={handleIncludePayment}
                            name="updateInterest"
                          />
                        }
                        sx={{ width: "min-content", color: "red" }}
                        label="Update Interest"
                      />
                    </FormGroup>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.updateMonth}
                            onChange={handleIncludePayment}
                            name="updateMonth"
                          />
                        }
                        sx={{ width: "min-content", color: "red" }}
                        label="Update Loan Term"
                      />
                    </FormGroup>
                  </Stack>
                ) : (
                  ""
                )}
              </Stack>
            </Box>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              mb: 3,
              width: "100%",
              justifyContent: "right",
              textAlign: "right",
            }}
          >
            <LoadingButton
              color="secondary"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type="button"
              onClick={handleSubmit}
              sx={{ fontSize: "1rem" }}
            >
              <span>Confirm</span>
            </LoadingButton>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentFormv1;
