import { useEffect, useState } from "react";
import { useGetUsersQuery } from "../app/api/users/usersApiSlice";
import { useGetAllLoansQuery } from "../app/api/loans/loanApiSlice";
import computationMonthly from "./useComputation";
import computeFlexible from "./useComputeFlexible";
import computer1025th from "./useCompute1025th";
import dayjs from "dayjs";

export function useDashboardData(id = "") {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [memberGroupData, setMemberGroupData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [loanData, setLoanData] = useState([]);
  const [loanPaymentData, setLoanPaymentData] = useState([]);
  const [loanInfoData, setLoanInfoData] = useState([]);
  const {
    data: users,
    isLoading: isUsersLoading,
    isSuccess: isUsersSuccess,
  } = useGetUsersQuery(id, {
    pollingInterval: 3600000,
    refetchOnWindowFocus: true, // Refetch when window regains focus
    refetchOnMountOrArgChange: true,
  }); // 1 hour in milliseconds
  const {
    data: loans,
    isLoading: isLoanLoading,
    isSuccess: isLoanSuccess,
  } = useGetAllLoansQuery(id, {
    pollingInterval: 3600000,
    refetchOnWindowFocus: true, // Refetch when window regains focus
    refetchOnMountOrArgChange: true,
  }); // 1 hour in milliseconds

  useEffect(() => {
    if (users && !isUsersLoading) {
      processUsersData(users);
    }
  }, [users, isUsersLoading]);

  useEffect(() => {
    if (loans && !isLoanLoading) {
      processLoansData(loans);
    }
  }, [loans, isLoanLoading]);

  const processUsersData = (users) => {
    const newData = [];
    const paymentData = { payment: [], widgetCount: [] };
    const borrowerData = {};
    const allPaymentData = { payment: [] };
    let countTodayPayment = 0;
    let currentDate = dayjs().format("YYYY-MM-DD");
    let allDataArray = [];
    let todayPayment = 0;
    let countNewlyApplied = 0;
    let withdrawnAmount = 0;

    if (Array.isArray(users)) {
      allDataArray = users;
    } else {
      allDataArray = Object.entries(users.entities).map(
        ([key, value]) => value
      );
    }
    allDataArray.forEach((user) => {
      const rowUser = user.borrower;
      const rowLoans = user.loans;
      if (!borrowerData[rowUser.membersgroup]) {
        borrowerData[rowUser.membersgroup] = {};
      }
      borrowerData[rowUser.membersgroup][user.id] = rowUser;

      rowLoans.forEach((loan) => {
        if (loan.status === 0 || loan.status === 2) {
          if (loan.status === 0) {
            console.log(dayjs(loan.applied_date).format("YYYY-MM-DD"));
            if (dayjs(loan.applied_date).format("YYYY-MM-DD") === currentDate) {
              countNewlyApplied++;
            }
          }
          if (loan.payments.length > 0 && loan.status === 2) {
            loan.payments.forEach((payment) => {
              if (payment.status === 0) {
                if (
                  dayjs(payment.created_date).format("YYYY-MM-DD") ===
                  currentDate
                ) {
                  todayPayment += payment.amount;
                  countTodayPayment++;
                }
              } else {
                if (
                  dayjs(payment.created_date).format("YYYY-MM-DD") ===
                  currentDate
                ) {
                  withdrawnAmount += payment.amount;
                }
              }
            });
          }
        }
        if (loan.payments.length > 0) {
          allPaymentData.payment.push(...loan.payments);
        }
      });
      const userData = {
        name: `${rowUser.firstname} ${rowUser.lastname}`,
        id: user._id,
        uid: user.id,
        //count_pending: countPending,
        //count_active: countActive,
        num_loans: rowLoans.length,
      };
      newData.push(userData);
    });

    paymentData.payment = todayPayment - withdrawnAmount;
    paymentData.widgetCount = countTodayPayment;
    paymentData.newlyApplied = countNewlyApplied;
    setPaymentData(paymentData);
    //setPaymentData({ payment: todayPayment, widgetCount: countTodayPayment });
    //setPaymentData(todayPayment);
    setData(newData);
    setLoanPaymentData(allPaymentData);
    setAllData(allDataArray); // Setting allData to an array
    setMemberGroupData(borrowerData);
  };

  const processLoansData = (loans) => {
    let loanData = {};
    let loanInfo = [];
    let computationData = {};

    loans.forEach((loan) => {
      // Group loans by status if not already grouped
      if (!loanData[loan.status]) {
        loanData[loan.status] = {};
      }

      // Store the loan data by loan ID
      loanData[loan.status][loan.id] = loan;

      // Prepare computation data
      computationData = {
        amount: loan.loan_amount,
        interest: loan.loan_interest,
        term: loan.loan_terms,
        loanId: loan.id,
        payment: loan.payments,
        effectived_date: loan.effectived_date,
        loan_type: loan.loan_type,
        loan_status: loan.status,
        applied_date: loan.applied_date,
      };

      // Compute based on loan type
      let computationResult = [];
      if (loan.loan_type === 0) {
        computationResult = computationMonthly(computationData);
      } else if (loan.loan_type === 1) {
        computationResult = computeFlexible(computationData);
      } else if (loan.loan_type === 2) {
        computationResult = computer1025th(computationData);
      }

      // Store detailed loan info by loan ID
      loanInfo[loan.id] = {
        comaker_firstname: loan.comaker_firstname,
        comaker_lastname: loan.comaker_lastname,
        comaker_middlename: loan.comaker_middlename,
        firstname: loan.firstname,
        lastname: loan.lastname,
        middlename: loan.middlename,
        loan_amount: loan.loan_amount,
        loan_interest: loan.loan_interest,
        applied_date: loan.applied_date,
        effectived_date: loan.effectived_date,
        status: loan.status,
        loan_type: loan.loan_type,
        loan_terms: loan.loan_terms,
        borrower_id: loan.borrower_id,
        computed: computationResult,
      };
    });

    // Set state with processed data
    setLoanInfoData(loanInfo);
    setLoanData(loanData);
  };

  return {
    data,
    allData,
    isLoading: isUsersLoading,
    isLoanLoading,
    isSuccess: isUsersSuccess,
    isLoanSuccess,
    paymentData,
    memberGroupData,
    loanData,
    loanPaymentData,
    loanInfoData,
  };
}
