import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import dayjs from "dayjs";

function ccyFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export default function BorrowerTable({ rows }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 700 }}>
              Registered Date
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 700 }}>
              Name
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 700 }}>
              Address
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 700 }}>
              Member Group
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 700 }}>
              Email
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 700 }}>
              Phone Number
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 700 }}>
              Co-maker name
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 700 }}>
              Total Loans
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">
                {dayjs(row.registeredDate).format("MMM DD, YYYY")}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell align="left">{row.address}</TableCell>
              <TableCell align="left">{row.memberGroup}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.phoneNumber}</TableCell>
              <TableCell align="left">{row.coMakerName}</TableCell>
              <TableCell align="left">{row.totalLoans}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
