import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import "./Member.css";
import { ModalData } from "../../components/modals/Modal";
import { CustomSnackbar } from "../../components/popover/Snackbar";
import useMemberForm from "../../hooks/useMemberForm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import PersonalInfoContent from "./membersForm/PersonalInfo";
import LoanContent from "./membersForm/LoanInfo";
import CoMakerContent from "./membersForm/CoMakerInfo";
import CoMakerSignContent from "./membersForm/CoMakerSign";
import BorrowerSignContent from "./membersForm/BorrowerSign";
import UploadAttachment from "./membersForm/UploadAttachment";
import ReloanInfo from "./reloanForm/ReloanInfo";
import ResponsiveDialog from "../../components/modals/Dialog";
import { MemberModal } from "../../components/modals/MemberModals";
import { ModalPrintPreview } from "../../components/modals/ModalPrintPreview";

const FormTitleLabel = (type) => {
  switch (type) {
    case "add":
      return (
        <Grid item xs={12} md={12}>
          <Grid
            item
            xs={12}
            alignItems={"center"}
            className="gridLabelTitle"
            marginBottom={3}
          >
            <Typography variant="h6" fontWeight="bold" fontSize={24}>
              NEW LOAN APPLICATION
            </Typography>
          </Grid>
        </Grid>
      );
    default:
      return null;
  }
};

function MembersData() {
  const {
    borrower_id,
    comaker_id,
    promisory_note,
    certification_doc,
    acknowledgement,
    loanTypes,
    type,
    monthlyTerm,
    loanLabelType,
    handleFileChange,
    uploadValue,
    selectedImage,
    comakerSrc,
    setApplyDate,
    applyDate,
    control,
    handleSubmit,
    loanValue,
    setLoanValue,
    genderValue,
    memberGroupValue,
    setMemberGroupValue,
    borrowerValue,
    setEffectiveDate,
    effectiveDate,
    notifMessage,
    popoverOpen,
    notifType,
    imgSrc,
    signSrcBorrower,
    signSrcCo,
    coMakerValue,
    setcoMakerValue,
    civilStatusValue,
    modalOpen,
    setModalOpen,
    modalTitle,
    modalType,
    isLoading,
    onSubmit,
    handlecoMakerChange,
    handleGenderChange,
    handleMemberGrpChange,
    handleLoanTypeChange,
    handlecivilStatsChange,
    handleCloseSnackbar,
    handleSign,
    handleButtonClick,
    handleBorrowerChange,
    handleImg,
    handleloanLabelType,
    watch,
    id,
    loanData,
    handlePaymentModal,
    reqDataValue,
    dialogOpen,
    handleDialogOpen,
    handleDiloagClose,
    dialogContent,
    handleDialogYes,
    modalMemberOpen,
    modalMemberType,
    handleReloanSubmit,
    handleButtonMemberClick,
    setLoanInterest,
    loanInterest,
    dialogType,
    userCardData,
    comakerData,
    userData,
    userFetchData,
    setcomakerSrc,
    setValue,
    setsignSrcCo,
    // Add other handler functions here...
  } = useMemberForm(); // Use the custom hook here
  const navigate = useNavigate();
  const [modalPreview, setModalPreview] = React.useState(false);

  const handlePrintPreview = () => {
    setModalPreview(true);
  };

  const handleModalPreviewClose = () => {
    setModalPreview(false);
  };

  return (
    <>
      <Box
        component="form"
        validate="true"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1 }}
        className="memberFormData"
      >
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/operator-dashboard")}
          >
            Back
          </Button>
          {type === "view" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrintPreview}
              sx={{ float: "right" }}
            >
              Print Preview
            </Button>
          ) : (
            ""
          )}
        </Paper>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <CssBaseline />
            {FormTitleLabel(type)}

            <PersonalInfoContent
              control={control}
              setEffectiveDate={setEffectiveDate}
              handleBorrowerChange={handleBorrowerChange}
              setApplyDate={setApplyDate}
              applyDate={applyDate}
              borrowerValue={borrowerValue}
              setMemberGroupValue={setMemberGroupValue}
              handleMemberGrpChange={handleMemberGrpChange}
              memberGroupValue={memberGroupValue}
              civilStatusValue={civilStatusValue}
              imgSrc={imgSrc}
              handleSign={handleSign}
              handleGenderChange={handleGenderChange}
              handlecivilStatsChange={handlecivilStatsChange}
              genderValue={genderValue}
              watch={watch}
              type={type}
            />
            {parseInt(borrowerValue) === 0 ? (
              <CoMakerContent
                coMakerValue={coMakerValue}
                control={control}
                setcoMakerValue={setcoMakerValue}
                handleSign={handleSign}
                handlecoMakerChange={handlecoMakerChange}
                comakerSrc={comakerSrc}
                type={type}
                watch={watch}
                setValue={setValue}
                setcomakerSrc={setcomakerSrc}
                setsignSrcCo={setsignSrcCo}
              />
            ) : (
              ""
            )}
            {type === "reloan" || type === "view" ? (
              <ReloanInfo
                data={loanData}
                paymentclick={handlePaymentModal}
                isLoading={isLoading}
                handleDialogOpen={handleDialogOpen}
              />
            ) : (
              <>
                {type !== "edit" && (
                  <LoanContent
                    control={control}
                    handleloanLabelType={handleloanLabelType}
                    loanLabelType={loanLabelType}
                    monthlyTerm={monthlyTerm}
                    loanTypes={loanTypes}
                    setLoanValue={setLoanValue}
                    handleLoanTypeChange={handleLoanTypeChange}
                    loanValue={loanValue}
                    setEffectiveDate={setEffectiveDate}
                    id={id}
                    setValue={setValue}
                  />
                )}
                <UploadAttachment
                  handleFileChange={handleFileChange}
                  uploadValue={uploadValue}
                  title="Upload Attachment"
                  selectedImage={selectedImage}
                  borrower_id={borrower_id}
                  comaker_id={comaker_id}
                  promisory_note={promisory_note}
                  certification_doc={certification_doc}
                  acknowledgement={acknowledgement}
                />
                {parseInt(borrowerValue) === 0 ? (
                  <CoMakerSignContent
                    signSrcBorrower={signSrcBorrower}
                    handleSign={handleSign}
                    signSrcCo={signSrcCo}
                    type={type}
                  />
                ) : (
                  <BorrowerSignContent
                    signSrcBorrower={signSrcBorrower}
                    handleSign={handleSign}
                  />
                )}
                <Grid item xs={12} textAlign={"center"} md={12} marginTop={3}>
                  <LoadingButton
                    color="secondary"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    type="submit"
                    sx={{ fontSize: "1.5rem" }}
                  >
                    <span>{type === "edit" ? "update" : "Save"}</span>
                  </LoadingButton>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Box>
      {modalOpen && (
        <ModalData
          closeModal={handleButtonClick}
          onSubmit={handleButtonClick}
          onCancel={handleButtonClick}
          openModal={modalOpen}
          title={modalTitle}
          type={modalType}
          profileName=""
          reqValue={reqDataValue}
          cameraImg={handleImg}
          signatureImg={handleImg}
          borrowerValue={borrowerValue}
          handleSign={handleSign}
          handleFileChange={handleFileChange}
          promisory_note={promisory_note}
          certification_doc={certification_doc}
          acknowledgement={acknowledgement}
        />
      )}

      {modalMemberOpen && (
        <MemberModal
          closeModal={handleButtonMemberClick}
          onSubmit={handleButtonMemberClick}
          onCancel={handleButtonMemberClick}
          openModal={modalMemberOpen}
          title={modalTitle}
          type={modalMemberType}
          profileName=""
          reqValue={reqDataValue}
          borrowerValue={borrowerValue}
          handleSign={handleSign}
          handleFileChange={handleFileChange}
          promisory_note={promisory_note}
          certification_doc={certification_doc}
          acknowledgement={acknowledgement}
          signSrcBorrower={signSrcBorrower}
          signSrcCo={signSrcCo}
          handleSubmit={handleReloanSubmit}
          loanValue={loanValue}
          setLoanValue={setLoanValue}
          setLoanInterest={setLoanInterest}
          loanInterest={loanInterest}
          isLoading={isLoading}
        />
      )}

      {dialogOpen && (
        <ResponsiveDialog
          dialogOpen={dialogOpen}
          handleDialogClose={handleDiloagClose}
          dialogContent={dialogContent}
          BackdropClick={true}
          handleDialogYes={handleDialogYes}
          setEffectiveDate={setEffectiveDate}
          effectiveDate={effectiveDate}
          dialogType={dialogType}
        />
      )}
      {modalPreview && (
        <ModalPrintPreview
          closeModal={handleModalPreviewClose}
          onSubmit={handleModalPreviewClose}
          onCancel={handleModalPreviewClose}
          openModal={modalPreview}
          userCardData={userCardData}
          comakerData={comakerData}
          userFetchData={userFetchData}
          watch={watch}
        />
      )}
      {
        <CustomSnackbar
          openSnackBar={popoverOpen}
          closeSnackBar={handleCloseSnackbar}
          messageData={notifMessage}
          colorVariant={notifType}
        />
      }
    </>
  );
}

export default MembersData;
