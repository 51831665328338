import { Box, Button, Grid } from "@mui/material";
import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadButton = ({
  selectedImage,
  handleFileChange,
  uploadValue,
  title,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} md={12}>
          <img
            src={
              selectedImage
                ? URL.createObjectURL(selectedImage)
                : "/assets/noimage.jpg"
            }
            alt="Selected"
            style={{
              maxWidth: "100%",
              width: "100%",
              height: "200px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Grid>
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          {title}
          <input
            type="file"
            accept="image/*"
            defaultValue=""
            style={{ display: "none" }}
            data-value={uploadValue}
            onChange={handleFileChange}
          />
        </Button>
      </Box>
    </>
  );
};
export default UploadButton;
