import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Typography } from "@mui/material";

const chartSetting = {
  height: 400,
};

const defaultDataset = [
  {
    pending: 59,
    rejected: 57,
    approved: 86,
    closed: 21,
    month: "Jan",
  },
];

const valueFormatter = (value) => `${value}`;

export default function BarChartWidget({ barChartData = [], isLoading }) {
  const dataset =
    !isLoading && barChartData.length > 0 ? barChartData : defaultDataset;

  console.log("dataset", dataset);

  if (!Array.isArray(dataset) || dataset.length === 0) {
    console.error("Invalid dataset", dataset);
    return null;
  }

  return (
    <>
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, textAlign: "center", marginBottom: "20px" }}
      >
        Number of Loans
      </Typography>
      <BarChart
        dataset={dataset}
        xAxis={[{ scaleType: "band", dataKey: "month" }]}
        series={[
          { dataKey: "pending", label: "Pending", valueFormatter },
          { dataKey: "rejected", label: "Rejected", valueFormatter },
          { dataKey: "approved", label: "Approved", valueFormatter },
          { dataKey: "closed", label: "Closed", valueFormatter },
        ]}
        barLabel="value"
        {...chartSetting}
        onReady={(event) => {
          console.log("BarChart event:", event);
          if (event.bars) {
            console.log("Bars data:", event.bars);
          }
        }}
      />
    </>
  );
}
