import React, { useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { useAddOperatorMutation } from "../../../app/api/users/usersApiSlice";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import WebSocketService from "../../../app/api/ws/WebSocketService";

const socketService = WebSocketService.getInstance();

function AddOperators({
  onClose,
  setSnackBarMsg,
  setSnackBarColor,
  setPopoverOpen,
  type,
  operatorData,
}) {
  const [operatorName, setOperatorName] = useState(
    type === "Edit" ? operatorData.Name : ""
  );
  let operatorRole =
    type === "Edit" ? (operatorData.Role === "Admin" ? 2001 : 1001) : 1001;
  const [operatorPassword, setOperatorPassword] = useState("");
  const [role, setRole] = useState(operatorRole); // Default value for Operator role
  const [error, setError] = useState(false);
  const [operatorUsername, setOperatorUsername] = useState(
    type === "Edit" ? operatorData.Username : ""
  );
  const [addOperator, { isLoading }] = useAddOperatorMutation(); // [1]

  const handleOperatorUsernameChange = (event) => {
    setOperatorUsername(event.target.value);
    setError(event.target.value === "");
  };

  const handleOperatorNameChange = (event) => {
    setOperatorName(event.target.value);
    setError(event.target.value === "");
  };

  const handleOperatorPasswordChange = (event) => {
    setOperatorPassword(event.target.value);
    setError(event.target.value === "");
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  console.log(operatorData);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      username: operatorUsername,
      password: operatorPassword,
      roles: role,
      name: operatorName,
      type: type,
    };
    if (type === "Edit") {
      formData.id = operatorData.id;
    }

    try {
      const result = await addOperator(formData).unwrap();
      if (result) {
        const dataToSend = { type: "refresh" };
        onClose();
        setPopoverOpen(true);
        setSnackBarMsg(result.message);
        setSnackBarColor("success");
        socketService.sendMessage(dataToSend);
      }
    } catch (error) {
      setSnackBarMsg(error.data.message);
      setSnackBarColor("error");
      setPopoverOpen(true);
    }
  };

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px" }}
      sx={{ flexDirection: "column" }}
    >
      <Box
        component="form"
        validate="true"
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
        className="memberFormData"
      >
        <Typography variant="h4" sx={{ fontWeight: 700 }} gutterBottom>
          {type} Operator
        </Typography>
        <Stack direction={"column"} spacing={2}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ display: "flex", alignItems: "center" }}
            spacing={2}
          >
            <Typography sx={{ width: "150px" }}>Operator Username:</Typography>
            <TextField
              label="Username"
              type="text"
              value={operatorUsername}
              onChange={handleOperatorUsernameChange}
              style={{ width: "100%" }}
              error={error}
              helperText={error ? "Please input operator username" : ""}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ display: "flex", alignItems: "center" }}
            spacing={2}
          >
            <Typography sx={{ width: "150px" }}>Operator Password:</Typography>
            <TextField
              label="Password"
              type="password"
              value={operatorPassword}
              onChange={handleOperatorPasswordChange}
              style={{ width: "100%" }}
              error={error}
              helperText={error ? "Please input operator password" : ""}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ display: "flex", alignItems: "center" }}
            spacing={2}
          >
            <Typography sx={{ width: "150px" }}>Operator Name:</Typography>
            <TextField
              label="Name"
              type="text"
              value={operatorName}
              onChange={handleOperatorNameChange}
              style={{ width: "100%" }}
              error={error}
              helperText={error ? "Please input operator name" : ""}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ display: "flex", alignItems: "center" }}
            spacing={2}
          >
            <Typography sx={{ width: "150px" }}>Operator Role:</Typography>
            <Select
              value={role}
              onChange={handleRoleChange}
              style={{ width: "100%" }}
            >
              <MenuItem value={2001}>Admin</MenuItem>
              <MenuItem value={1001}>Operator</MenuItem>
            </Select>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "right",
              justifyContent: "flex-end",
            }}
            spacing={2}
          >
            <LoadingButton
              color="secondary"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type="submit"
              sx={{ fontSize: "1.5rem" }}
            >
              <span>{type === "Edit" ? "Update" : "Save"}</span>
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
}

export default AddOperators;
