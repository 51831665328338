import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import React, { useEffect } from "react";
import "../Member.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
const LoanContent = ({
  control,
  handleloanLabelType,
  loanLabelType,
  monthlyTerm,
  loanTypes,
  setLoanValue,
  handleLoanTypeChange,
  loanValue,
  value,
  setEffectiveDate,
  effectiveDate,
  id,
  setValue,
}) => {
  useEffect(() => {
    if (parseInt(loanLabelType) === 2) {
      setValue("loanTerm", 1);
    }
  }, [loanLabelType]);

  return (
    <Grid item xs={12} md={12}>
      <Grid item xs={12} className="gridLabelTitle" marginTop={2}>
        <Typography variant="h6" fontWeight="bold">
          TRANSACTION INFORMATION
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <FormControl>
          <FormLabel id="loan-type">Loan Type</FormLabel>
          <RadioGroup
            row
            aria-labelledby="loan-type"
            name="loanLabelType"
            onChange={handleloanLabelType}
            value={loanLabelType}
          >
            <FormControlLabel value="0" control={<Radio />} label="Monthly" />
            <FormControlLabel value="1" control={<Radio />} label="Flexible" />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="15th & 25th"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Stack spacing={2} direction={"row"} marginTop={2}>
        <Grid item xs={4}>
          <Controller
            name="loanAmount"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Amount Loan"
                type="text"
                fullWidth
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            id="loanType"
            freeSolo
            name="loanTerm"
            options={loanTypes.map((option) => option.title)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Monthly Interest"
                required
                InputProps={{
                  ...params.InputProps, // Preserve other props
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            )}
            onChange={(event, newValue) => {
              const newLoanValue = newValue || "";
              setLoanValue(newLoanValue);
              handleLoanTypeChange(event, newLoanValue);
            }}
            value={loanValue}
            inputValue={loanValue}
            onInputChange={(event, newInputValue) => {
              const newLoanValue = newInputValue || "";
              setLoanValue(newLoanValue);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="loanTerm"
            control={control}
            defaultValue={parseInt(loanLabelType) === 2 ? 1 : ""}
            render={({ field }) => (
              <TextField
                {...field}
                label="Loan term"
                type="number"
                fullWidth
                required
                disabled={parseInt(loanLabelType) === 2 ? true : false}
              />
            )}
          />
        </Grid>
      </Stack>
      <Stack spacing={2} direction={"row"} marginTop={2}>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ width: "100%" }} className="datePickerBox">
              <DatePicker
                value={effectiveDate}
                label="Payment Effective on"
                style={{ width: "100% !important;" }}
                onChange={(newValue) => setEffectiveDate(newValue)}
                required
              />
            </Box>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="loanPurpose"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Loan Purpose" multiline fullWidth />
            )}
          />
        </Grid>
      </Stack>
      <Stack spacing={2} direction={"row"} marginTop={2}>
        <Grid item xs={8}>
          <Controller
            name="notes"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Notes" multiline fullWidth />
            )}
          />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default LoanContent;
