import React, { useEffect, useState } from "react";
import {
  useDeleteBorrowerMutation,
  useGetUsersQuery,
} from "../../../app/api/users/usersApiSlice";
import { Paper, Typography } from "@mui/material";
import CustomTable from "../../../components/tables/Table";
import DottedButton from "../../../components/buttons/DottedButtons.js";
import { CSVDownload } from "react-csv";
import dayjs from "dayjs";
import SharedDialog from "../../../components/modals/SharedDialog.js";
import { CustomSnackbar } from "../../../components/popover/Snackbar";
import socketService from "../../../app/api/ws/initializeWebSocketService.js";

function BorrowersList() {
  const [borrowerData, setBorrowerData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogChoice, setDialogChoice] = useState("");
  const [borrowerId, setBorrowerId] = useState("");
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [snackBarColor, setSnackBarColor] = React.useState("");
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [dialogMsg, setDialogMsg] = useState("");
  const [status, setStatus] = useState("");
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery("");

  const [deleteBorrower] = useDeleteBorrowerMutation();

  const handleCloseSnackbar = () => {
    setPopoverOpen(false);
  };

  useEffect(() => {
    const deleteBorrowerAsync = async () => {
      if (dialogChoice === 1) {
        try {
          let res = await deleteBorrower({
            id: borrowerId,
            status: status,
            type: "borrower",
          }).unwrap();
          if (res) {
            setDialogChoice("");
            setSnackBarMsg("Successfully set borrower to inactive");
            setSnackBarColor("success");
            setPopoverOpen(true);
            socketService.sendMessage({ type: "refresh" });
          }
          // Handle success (e.g., show notification, refresh list)
        } catch (error) {
          setSnackBarMsg("Failed to set borrower to inactive");
          setSnackBarColor("error");
          setPopoverOpen(true);
          // Handle error (e.g., show error message)
        }
      }
    };

    deleteBorrowerAsync();
  }, [dialogChoice, borrowerId, deleteBorrower, status]);

  useEffect(() => {
    if (users && !isUsersLoading) {
      processUsersData(users);
    }
  }, [users, isUsersLoading]);

  useEffect(() => {
    if (isDownload) {
      setIsDownload(false); // Reset isDownload after initiating the download
    }
  }, [isDownload]);

  const processUsersData = (users) => {
    let allDataArray = [];
    if (Array.isArray(users)) {
      allDataArray = users;
    } else {
      allDataArray = Object.entries(users.entities).map(
        ([key, value]) => value
      );
    }
    setBorrowerData(allDataArray);
  };

  const printCSV = () => {
    const csv = [];
    csv.push(["Borrowers List"]);
    csv.push([
      "Borrower ID",
      "Name",
      "Address",
      "Contact Number",
      "Email",
      "Civil Status",
      "Spouse Name",
      "Co-Maker Name",
      "Signature",
      "Image Profile",
      "Created On",
      "Borrower Status",
    ]);

    borrowerData.forEach((item) => {
      csv.push([
        item.id,
        `${item.borrower.firstname} ${item.borrower.middlename} ${item.borrower.lastname}`,
        item.borrower.address,
        item.contact.phonenumber,
        item.contact.email,
        parseInt(item.borrower.civil_status) === 1 ? "Married" : "Single",
        parseInt(item.borrower.civil_status) === 1
          ? `${item.spouse.firstname} ${item.spouse.middlename} ${item.spouse.lastname}`
          : "",
        `${item.comaker.name.firstname} ${item.comaker.name.middlename} ${item.comaker.name.lastname}`,
        item.attachments.signature ? "Yes" : "No",
        item.attachments.image_profile ? "Yes" : "No",
        dayjs(item.created_on).format("MM/DD/YYYY"),
        parseInt(item.status) === 1 ? "Inactive" : "Active",
      ]);
    });
    setCsvData(csv);
    setIsDownload(true);
  };

  //Dashboard table data
  let options = {};
  options = {
    search: true,
    download: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    jumpToPage: true,
    responsive: "standard", // Options: "standard", "vertical", "simple"
    print: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    setRowProps: (row, dataIndex) => {
      if (dataIndex === 0) {
        return {
          className: "MuiDataTable-FirstRow",
          style: { display: "none !important" },
        };
      }
      return {};
    },
    customHeadRender: () => {
      return <th style={{ display: "none !important" }} />;
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      printCSV();
      return false; // Returning true will proceed with the default download behavior
    },
  };

  const title = "";
  const btnOptions = ["View", "Edit", "Delete"];
  const customBodyRenderFunction = (dataIndex, rowData) => {
    const data = rowData[dataIndex]; // Assuming rowData[dataIndex] gives the required row data
    //console.log(data.status === "Inactive" ? "Activate" : "Deactivate");

    if (data) {
      const statusButton =
        data.status === "Inactive" ? "Activate" : "Deactivate";
      const updatedBtnOptions = [...btnOptions.slice(0, 2), statusButton];
      return (
        <DottedButton
          options={updatedBtnOptions}
          //data={loanInfoData[dataIndex]}
          borrower_id={data.borrower_id} // Adding borrower_id here
          isDialogOpen={setIsDialogOpen}
          dialogMsg={setDialogMsg}
          uid={data.borrower_id}
          id={data.borrower_id}
          loanStatus={data.loan_status}
          setUserId={setBorrowerId}
          type={"view"}
          status={setStatus}
        />
      );
    } else {
      return null; // Return null or any fallback if data is not available
    }
  };

  const columns = [
    { name: "Borrower ID", options: { display: false } },
    { name: "Name", options: { filterOptions: { fullWidth: true } } },
    { name: "Address" },
    { name: "Contact Number" },
    { name: "Email" },
    { name: "Civil Status" },
    { name: "Spouse Name" },
    { name: "Borrower Status" },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) =>
          customBodyRenderFunction(dataIndex, tableData),
      },
    },
  ];

  const sortColumn = {
    name: "Status",
    direction: "asc", // or 'desc'
  };

  const tableData = borrowerData
    //.filter((item) => item.status === 0) // Filter out items where status is not 0
    .map((item) => ({
      borrower_id: item.id, // Include borrower_id in the data
      name: `${item.borrower.firstname} ${item.borrower.middlename} ${item.borrower.lastname}`,
      address: item.borrower.address,
      contact_number: item.contact.phonenumber,
      email: item.contact.email,
      civil_status:
        parseInt(item.borrower.civil_status) === 1 ? "Married" : "Single",
      spouse_name:
        parseInt(item.borrower.civil_status) === 1
          ? `${item.spouse.firstname} ${item.spouse.middlename} ${item.spouse.lastname}`
          : "",
      status: item.status === 1 ? "Inactive" : "Active",
    }));

  //end of dashboard table

  let content = "";
  content = (
    <Paper elevation={3} style={{ padding: "20px" }}>
      <Typography variant="h4" sx={{ fontWeight: 700 }} gutterBottom>
        Borrowers List
      </Typography>
      <CustomTable
        title={title}
        data={tableData}
        columns={columns}
        options={options}
        sortColumn={sortColumn}
      />
      {isDownload && (
        <CSVDownload
          data={csvData}
          target="_blank"
          filename={"borrowers.csv"}
        />
      )}
      {popoverOpen && (
        <CustomSnackbar
          openSnackBar={popoverOpen}
          closeSnackBar={handleCloseSnackbar}
          messageData={snackBarMsg}
          colorVariant={snackBarColor}
        />
      )}
      {isDialogOpen && (
        <SharedDialog
          isOpen={isDialogOpen}
          isDialogOpen={setIsDialogOpen}
          title={"Borrower"}
          bodyContent={dialogMsg}
          setDialogChoice={setDialogChoice}
        />
      )}
    </Paper>
  );

  return content;
}

export default BorrowersList;
