import { Box, Button, Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useGetLoginUsersQuery } from "../../app/api/users/usersApiSlice";
import { ModalData } from "../../components/modals/Modal";
import AddOperators from "./operators/AddOperators";
import { CustomSnackbar } from "../../components/popover/Snackbar";
import UserManagementBtn from "../../components/buttons/UserManagementButtons";
import SharedDialog from "../../components/modals/SharedDialog";
import { useDeleteBorrowerMutation } from "../../app/api/users/usersApiSlice";
import socketService from "../../app/api/ws/initializeWebSocketService";

function ManageUsers() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarColor, setSnackBarColor] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [operatorData, setOperatorData] = useState([]);
  const [operatorId, setOperatorId] = useState("");
  const [dialogChoice, setDialogChoice] = useState("");
  const [status, setStatus] = useState("");
  const [deleteBorrower] = useDeleteBorrowerMutation();

  const { data: userData } = useGetLoginUsersQuery({
    refetchOnWindowFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiChip: {
          root: {
            backgroundColor: "pink",
          },
        },
      },
    });

  const btnOptions = ["Edit", "Delete"];

  const customBodyRenderFunction = (dataIndex, rowData) => {
    const data = rowData[dataIndex];
    if (data) {
      return (
        <UserManagementBtn
          options={btnOptions}
          isDialogOpen={setIsDialogOpen}
          uid={data.id}
          id={data.id}
          setOperatorData={setOperatorData}
          operatorData={data}
          setUserId={setOperatorId}
          setModalOpen={setModalOpen}
          setStatus={setStatus}
          setModalType={setModalType}
        />
      );
    } else {
      return null;
    }
  };

  const data = userData?.map((user) => ({
    id: user.id,
    Username: user.username,
    Name: user.name,
    Role: user.roles.User === 1001 ? "Operator" : "Admin",
  }));

  const columns = [
    {
      name: "Username",
      label: "Username",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Role",
      label: "Role",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) =>
          customBodyRenderFunction(dataIndex, data),
      },
    },
  ];

  const handleAddUser = () => {
    setModalType("Add");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseSnackbar = () => {
    setPopoverOpen(false);
  };

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    print: false,
    filter: false,
    selectableRows: "none",
  };

  const handleDialogYes = useCallback(async () => {
    try {
      const result = await deleteBorrower({
        id: operatorId,
        type: "operator",
        status: 1,
      }).unwrap();
      if (result) {
        setSnackBarMsg(result.message);
        setSnackBarColor("success");
        socketService.sendMessage({ type: "refresh" });
        setPopoverOpen(true);
        setIsDialogOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [operatorId, deleteBorrower]);
  useEffect(() => {
    if (parseInt(dialogChoice) === 1) {
      handleDialogYes();
      setDialogChoice("");
    }
  }, [dialogChoice, status, handleDialogYes]);
  let content = (
    <>
      <Paper elevation={3} sx={{ p: 2, overflowX: "auto" }}>
        <h1>Manage Operators</h1>
        <Box height={20} />
        <Paper elevation={3} sx={{ p: 2, overflowX: "auto", mb: 2 }}>
          <Button variant="contained" color="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </Paper>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable columns={columns} data={data} options={options} />
        </ThemeProvider>
      </Paper>
      {modalOpen && (
        <ModalData
          openModal={modalOpen}
          closeModal={handleCloseModal}
          bodyContent={
            <AddOperators
              onClose={handleCloseModal}
              setSnackBarColor={setSnackBarColor}
              setSnackBarMsg={setSnackBarMsg}
              setPopoverOpen={setPopoverOpen}
              type={modalType}
              operatorData={operatorData}
            />
          }
          type="addOperator"
        />
      )}
      {popoverOpen && (
        <CustomSnackbar
          openSnackBar={popoverOpen}
          closeSnackBar={handleCloseSnackbar}
          messageData={snackBarMsg}
          colorVariant={snackBarColor}
        />
      )}
      {isDialogOpen && (
        <SharedDialog
          isOpen={isDialogOpen}
          isDialogOpen={setIsDialogOpen}
          title={"Remove Operator"}
          bodyContent={"Are you sure you want to remove this Operator?"}
          setDialogChoice={setDialogChoice}
        />
      )}
    </>
  );

  return content;
}

export default ManageUsers;
