import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  overflowY: "auto",
  maxHeight: "80vh",
}));
const ResponsiveDialog = ({
  handleDialogClose,
  dialogOpen,
  dialogContent,
  handleDialogYes,
  setEffectiveDate,
  effectiveDate,
  dialogType,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  let btnValue = 1;
  if (parseInt(dialogType) === 1) {
    btnValue = 2;
  }
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        aria-labelledby="responsive-dialog-title"
        sx={{
          maxHeight: "70vh",
        }}
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <StyledDialogContent>
          <DialogContentText fontSize={18} fontWeight={600}>
            {dialogContent}
            {parseInt(dialogType) === 1 ? (
              <Stack sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h6">Payment Effective Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="paymentInput"
                    value={effectiveDate}
                    onChange={(newValue) => setEffectiveDate(newValue)}
                    slotProps={{
                      toolbar: {
                        toolbarPlaceholder: "__",
                        toolbarFormat: "DD / MM / YYYY",
                        hidden: false,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            ) : (
              ""
            )}
          </DialogContentText>
        </StyledDialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleDialogClose}
            variant="contained"
            color="error"
          >
            No
          </Button>
          <Button
            onClick={handleDialogYes}
            autoFocus
            variant="contained"
            color="success"
            value={btnValue}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResponsiveDialog;
