import { Button, Stack } from "@mui/material";
import React from "react";
const CoMakerSignature = ({ handleSign, signSrcCo }) => {
  return (
    <>
      <Stack direction={"column"} spacing={2} style={{ width: "100%" }}>
        <Stack textAlign={"center"}>
          {signSrcCo ? (
            <img
              src={signSrcCo}
              style={{ width: "100%", height: "100px" }}
              alt="co-maker signature"
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "#FFFFF",
              }}
            ></div>
          )}
          <div style={{ borderBottom: "1px solid", width: "100%" }}></div>
          Co-Maker Signature
        </Stack>
        <Button data-value="coMaker" variant="contained" onClick={handleSign}>
          Sign
        </Button>
      </Stack>
    </>
  );
};

export default CoMakerSignature;
