import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import "./print.css";
import dayjs from "dayjs";

function ComakerDataFrom(data) {
  return (
    <>
      <Box
        className={`page-break ${
          data.comakerCertification ? "" : "exclude-from-print dont-show"
        }`}
      >
        <Paper
          elevation={3}
          sx={{ padding: "30px", marginBottom: "20px" }}
          className="comakerInfo"
        >
          <Grid container spacing={2} sx={{ padding: "40px" }}>
            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <h3>CERTIFICATION DOCUMENT</h3>
            </Grid>
            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <Typography
                variant="div"
                sx={{ fontSize: "14px", fontWeight: 700, float: "right" }}
              >
                Date: {dayjs().format("MMMM D, YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

export default ComakerDataFrom;
