import * as React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { Typography } from "@mui/material";

let data = [
  { id: 0, value: 10, label: "4PS" },
  { id: 1, value: 15, label: "AFP" },
  { id: 2, value: 20, label: "Others" },
];

const size = {
  height: 400,
};
export default function PieChartWidget({ pieChartData, isLoading }) {
  if (!isLoading && pieChartData.length > 0) {
    data = pieChartData;
  }
  return (
    <>
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, textAlign: "center", marginBottom: "20px" }}
      >
        Members Group
      </Typography>

      <PieChart
        series={[
          {
            arcLabel: (item) => `${item.label} (${item.value})`,
            arcLabelMinAngle: 45,
            data,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontWeight: "bold",
          },
        }}
        {...size}
      />
    </>
  );
}
