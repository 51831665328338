import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import UploadButton from "./UploadFileData";
const UploadAttachment = ({
  handleFileChange,
  selectedImage,
  borrower_id,
  comaker_id,
  promisory_note,
  certification_doc,
  acknowledgement,
}) => {
  return (
    <Grid item xs={12}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12} className="gridLabelTitle">
        <Typography variant="h6" fontWeight="bold">
          ATTACHMENTS
        </Typography>
      </Grid>
      <Stack spacing={2} direction={"row"} marginTop={2}>
        <Grid item xs={2}>
          <UploadButton
            selectedImage={borrower_id}
            handleFileChange={handleFileChange}
            uploadValue="borrower_id"
            title="Borrower ID"
          />
        </Grid>
        <Grid item xs={2}>
          <UploadButton
            selectedImage={comaker_id}
            handleFileChange={handleFileChange}
            uploadValue="comaker_id"
            title="Comaker ID"
          />
        </Grid>
        <Grid item xs={3}>
          <UploadButton
            selectedImage={promisory_note}
            handleFileChange={handleFileChange}
            uploadValue="promisory_note"
            title="Promisory Note"
          />
        </Grid>
        <Grid item xs={3}>
          <UploadButton
            selectedImage={certification_doc}
            handleFileChange={handleFileChange}
            uploadValue="certification_doc"
            title="Certification Docutment"
          />
        </Grid>
        <Grid item xs={2}>
          <UploadButton
            selectedImage={acknowledgement}
            handleFileChange={handleFileChange}
            uploadValue="acknowledgement"
            title="Acknowledgement"
          />
        </Grid>
      </Stack>
    </Grid>
  );
};
export default UploadAttachment;
