import MUIDataTable from "mui-datatables";
import React from "react";
import "./table.css";

export default function CustomTable({
  title,
  data,
  columns,
  options,
  sortColumn,
}) {
  let finalTableData = [];

  data.forEach((item) => {
    finalTableData.push(Object.values(item));
  });

  const rowStyles = (rowData) => {
    const isPending = rowData[10] === "Pending";
    const isApproved = rowData[10] === "Approved";
    const isRejected = rowData[10] === "Rejected";
    return isPending
      ? "highlight-row"
      : isApproved
      ? "highlight-row-green"
      : isRejected
      ? "highlight-row-red"
      : "";
  };

  return (
    <MUIDataTable
      title={title}
      data={finalTableData}
      columns={columns}
      options={{
        ...options,
        sortOrder: sortColumn,
        setRowProps: (rowData) => {
          return {
            className: rowStyles(rowData),
          };
        },
      }}
    />
  );
}
