import { Button, Grid, Stack } from "@mui/material";
import React from "react";
const CoMakerSignContent = ({
  signSrcBorrower,
  handleSign,
  signSrcCo,
  type,
}) => {
  return (
    <>
      <Grid item xs={12} md={12}></Grid>
      <Grid
        container
        item
        xs={6}
        textAlign={"center"}
        alignContent={"center"}
        justifyContent={"center"}
        display={"flex"}
        md={6}
      >
        <Grid item xs={12}>
          <Stack textAlign={"center"}>
            {signSrcBorrower ? (
              <img
                src={signSrcBorrower}
                style={{ width: "100%", height: "100px" }}
                alt="borrower signature"
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "#FFFFF",
                }}
              ></div>
            )}
            <div style={{ borderBottom: "1px solid", width: "100%" }}></div>
            Borrower Signature
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            data-value="borrower"
            variant="contained"
            onClick={handleSign}
          >
            Sign
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={6}
        textAlign={"center"}
        alignContent={"center"}
        justifyContent={"center"}
        display={"flex"}
      >
        <Grid item xs={12}>
          <Stack textAlign={"center"}>
            {signSrcCo ? (
              <img
                src={signSrcCo}
                style={{ width: "100%", height: "100px" }}
                alt="co-maker signature"
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "#FFFFF",
                }}
              ></div>
            )}
            <div style={{ borderBottom: "1px solid", width: "100%" }}></div>
            Co-Maker Signature
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            data-value="coMaker"
            variant="contained"
            onClick={handleSign}
            disabled={type === "edit" ? true : false}
          >
            Sign
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CoMakerSignContent;
