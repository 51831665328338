import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import "./print.css";

function decimalFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
function LoanForm({ userFetchData, watch, componentRef }) {
  return (
    <>
      <Paper elevation={3} style={{ padding: 30 }}>
        <Box ref={componentRef}>
          <Stack spacing={0} sx={{ padding: "40px;" }}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              UPPER 28TH LENDING CORPORATION
            </Typography>
            <Typography variant="p" sx={{ textAlign: "center" }}>
              28TH IB Upper Calarian, Zamboanga City
            </Typography>
            <Typography variant="p" sx={{ textAlign: "center" }}>
              Non-VAT Reg. Tin Number: 264-782-012-000
            </Typography>
            <Typography variant="p" sx={{ textAlign: "center" }}>
              Business Permit Registration Number: 08302
            </Typography>
            <Stack spacing={2} sx={{ marginTop: "50px" }}>
              <Typography
                sx={{
                  textAlign: "right",

                  fontWeight: 700,
                }}
              >
                Date:{" "}
                <span style={{ borderBottom: "1px solid" }}>
                  {dayjs().format("MM-DD-YYYY")}
                </span>
              </Typography>
              <Typography
                sx={{ fontWeight: 700, marginBottom: "10px !important" }}
              >
                I. Personal Information
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                sx={{ alignItems: "right" }}
                spacing={2}
              >
                <Stack spacing={0} sx={{ width: "60%" }}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Typography sx={{ width: "40px" }}>Name:</Typography>
                    <Typography
                      sx={{
                        borderBottom: "1px solid",
                        width: "100%",
                        fontWeight: 700,
                        textTransform: "capitalize",
                      }}
                    >
                      {""}
                      {watch("firstName") +
                        " " +
                        watch("middleName") +
                        " " +
                        watch("lastName")}
                    </Typography>
                  </Stack>

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={0}>
                    <Stack direction={"row"} sx={{ width: "40%" }} spacing={2}>
                      <Typography sx={{ width: "40px" }}> Rank:</Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {" "}
                        {watch("rank")}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} sx={{ width: "60%" }} spacing={2}>
                      <Typography sx={{ width: "300px" }}>
                        Serial Number:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {watch("serialNumber")}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack spacing={0}>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "230px" }}>
                        Unit Assignment:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                          textTransform: "capitalize",
                        }}
                      >
                        {watch("unitAssign")}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Typography sx={{ marginTop: "10px !important" }}>
                      Status:
                    </Typography>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <FormGroup>
                        <FormControlLabel
                          className="checkboxStatus"
                          control={
                            <Checkbox
                              defaultChecked={
                                parseInt(watch("civilStatus")) === 0
                              }
                              disabled={true}
                            />
                          }
                          label="Single"
                        ></FormControlLabel>
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          className="checkboxStatus"
                          control={
                            <Checkbox
                              defaultChecked={
                                parseInt(watch("civilStatus")) === 1
                              }
                              disabled={true}
                            />
                          }
                          label="Married"
                        />
                      </FormGroup>
                    </Stack>
                  </Stack>
                  <Stack spacing={0}>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "140px" }}>
                        Married to:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                          textTransform: "capitalize",
                        }}
                      >
                        {parseInt(watch("civilStatus")) === 1
                          ? watch("firstNameSpouse") +
                            " " +
                            watch("middleNameSpouse") +
                            " " +
                            watch("lastNameSpouse")
                          : ""}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "200px" }}>
                        Home Address:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                          textTransform: "capitalize",
                        }}
                      >
                        {watch("address")}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "250px" }}>
                        Cellphone Number:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {watch("phoneNumber")}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "250px" }}>
                        Telephone Number:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {watch("telePhoneNumber")}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "180px" }}>
                        Email Address:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {watch("emailAddress")}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      marginTop: "30px !important",
                      marginBottom: "20px !important",
                    }}
                  >
                    II. Transaction Information
                  </Typography>
                  <Stack spacing={0}>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "160px" }}>
                        Amount Loan:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: 700,
                        }}
                      >
                        ₱{decimalFormat(watch("loanAmount"))}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography style={{ width: "500px" }}>
                        By{" "}
                        <span
                          style={{
                            borderBottom: "1px solid",
                            width: "50px",
                            display: "inline-block",
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: 700,
                          }}
                        >
                          {watch("loanInterest")}%
                        </span>{" "}
                        Interest, Monthly Amortization:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "170px",
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: 700,
                        }}
                      >
                        ₱
                        {decimalFormat(
                          (parseInt(watch("loanAmount")) / 100) *
                            parseInt(watch("loanInterest"))
                        )}
                      </Typography>
                    </Stack>
                    <Typography>
                      Payable within:{" "}
                      <span
                        style={{
                          borderBottom: "1px solid",
                          width: "60px",
                          display: "inline-block",
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: 700,
                        }}
                      >
                        {watch("loanTerm")}
                      </span>{" "}
                      Months
                    </Typography>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "300px" }}>
                        Payment Effective On:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {dayjs(watch("paymentEffectiveDate")).format(
                          "MMM D, YYYY"
                        )}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "180px" }}>
                        Loan Purpose:
                      </Typography>
                      <Typography
                        sx={{ borderBottom: "1px solid", width: "100%" }}
                      >
                        {watch("loanPurpose")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack spacing={0}>
                  <Stack
                    spacing={2}
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {watch("imageBorrower") ? (
                      <Avatar
                        variant="rounded"
                        src={watch("imageBorrower")}
                        sx={{
                          width: "192px",
                          height: "192px",
                        }}
                      />
                    ) : (
                      <img
                        src="https://via.placeholder.com/150"
                        alt="placeholder"
                        height={250}
                      />
                    )}
                  </Stack>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    sx={{ justifyContent: "center" }}
                    spacing={2}
                    marginTop={"100px !important"}
                  >
                    <Stack
                      spacing={0}
                      sx={{ textAlign: "center", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          minWidth: "100px",
                          minHeight: "100px",
                          width: "100%",
                          border: "1px solid",
                        }}
                      ></Box>
                      <Typography>Left Thumb Mark</Typography>
                    </Stack>
                    <Stack
                      spacing={0}
                      sx={{ textAlign: "center", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          minWidth: "100px",
                          minHeight: "100px",
                          width: "100%",
                          border: "1px solid",
                        }}
                      ></Box>
                      <Typography>Right Thumb Mark</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Box sx={{ marginTop: "50px !important" }}>
              <Stack
                spacing={0}
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    width: "40%",
                    borderBottom: "1px solid",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {watch("signBorrower") ? (
                    <Avatar
                      src={watch("signBorrower")}
                      sx={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {watch("firstName") +
                        " " +
                        watch("middleName") +
                        " " +
                        watch("lastName")}
                    </Avatar>
                  ) : (
                    ""
                  )}
                </Typography>
                <Typography>Signature over Printed Name</Typography>
                <Typography sx={{ fontWeight: 700 }}>
                  Date:{" "}
                  <span style={{ borderBottom: "1px solid" }}>
                    {dayjs().format("MM-DD-YYYY")}
                  </span>
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Paper>
    </>
  );
}

export default LoanForm;
