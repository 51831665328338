import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";

export const CustomSnackbar = ({
  openSnackBar,
  messageData,
  colorVariant,
  closeSnackBar,
}) => {
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeSnackBar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={closeSnackBar}
        action={action}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={closeSnackBar}
          severity={colorVariant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {messageData}
        </Alert>
      </Snackbar>
    </div>
  );
};
