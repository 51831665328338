import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Avatar, Grid, Stack } from "@mui/material";
import dayjs from "dayjs";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import "./Stepper.css";

export default function VLineStepper(datavalue) {
  //const steps = [];
  const [activeStep, setActiveStep] = React.useState(
    parseInt(datavalue.paidTerms)
  );
  const [steperValue, setStepperValue] = React.useState([]);

  let amount = parseFloat(datavalue.amount);
  let type = parseInt(datavalue.type);
  let term = parseInt(datavalue.term);

  const loanAmount = (amount * type) / 100;
  const loanTerm = loanAmount * term;
  const finalAmount = amount + loanTerm;
  const monthlyPayment = finalAmount / term;

  let today;
  if (
    datavalue.effectived_date === null ||
    typeof datavalue.effectived_date === "undefined"
  ) {
    today = new Date();
  } else {
    today = new Date(datavalue.effectived_date);
  }
  const numberOfMonths = term ? term : 0;

  React.useEffect(() => {
    setActiveStep(parseInt(datavalue.paidTerms));
    let steperData = [];
    let lastId = 0;

    datavalue.payments.forEach((data) => {
      const date = new Date(today);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();
      let withdraw_amount = 0;
      let payment_date = dayjs(data.payment_date).format("MMM D, YYYY");

      if (data.status === 1) {
        withdraw_amount = data.amount;
        steperData[lastId].withdraw_amount = parseFloat(withdraw_amount);
        steperData[lastId].withdraw_date = payment_date;
        if (
          data.withdraw_info &&
          typeof data.withdraw_info.image_url !== "undefined"
        ) {
          steperData[lastId].withdrawn_image = data.withdraw_info.image_url;
        }
        if (
          data.withdraw_info &&
          typeof data.withdraw_info.signature !== "undefined"
        ) {
          steperData[lastId].withdrawn_signature = data.withdraw_info.signature;
        }

        if (
          data.withdraw_info &&
          typeof data.withdraw_info.attachment !== "undefined"
        ) {
          steperData[lastId].withdrawn_attachments =
            data.withdraw_info.attachment;
        }
      } else {
        steperData.push({
          label: `${month} ${day}, ${year}`,
          description: parseFloat(monthlyPayment),
          payment_date: payment_date,
          withdraw_amount: withdraw_amount,
          withdraw_date: payment_date,
          actual_amount: data.amount ? parseFloat(data.amount) : "",
          withdrawn_image: "",
          withdrawn_signature: "",
          withdrawn_attachments: "",
        });
        lastId = steperData.length - 1;
      }
    });
    setStepperValue(steperData);
  }, [datavalue]);

  const steps = [];

  for (let i = 0; i < numberOfMonths; i++) {
    const date = new Date(today);
    date.setMonth(today.getMonth() + i);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const step = steperValue[i] || {}; // Use existing steperValue if available, otherwise an empty object

    steps.push({
      label: `${month} ${day}, ${year}`,
      description: step.description || "",
      payment_date: step.payment_date || "",
      withdraw_amount: step.withdraw_amount || 0,
      withdraw_date: step.withdraw_date || "",
      payment_change: step.payment_change || 0,
      withdrawn_image: step.withdrawn_image || "",
      withdrawn_signature: step.withdrawn_signature || "",
      withdrawn_attachments: step.withdrawn_attachments || "",
      actual_amount: step.actual_amount || 0,
    });
  }

  let content;
  if (datavalue.loan_type === 0) {
    content = (
      <Box style={{ width: "100%" }}>
        <Stack>
          <Stack direction={"row"} spacing={2}>
            <Typography variant="body1" style={{ width: "335px" }}>
              Remaining Balance:
            </Typography>
            <Typography
              variant="body1"
              style={{ width: "30%", fontWeight: 700 }}
            >
              ₱
              {datavalue.remaining_balance.toLocaleString("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Typography
              variant="body1"
              style={{ width: "32%" }}
              fontWeight={700}
              fontSize={18}
            >
              Due Date
            </Typography>
            <Typography
              variant="body1"
              style={{ width: "30%" }}
              fontWeight={700}
              fontSize={18}
            >
              Monthly Amortization
            </Typography>
            <Typography
              variant="body1"
              style={{ width: "30%" }}
              fontWeight={700}
              fontSize={18}
            >
              Payment Made
            </Typography>
            <Typography
              variant="body1"
              style={{ width: "30%" }}
              fontWeight={700}
              fontSize={18}
            >
              Actual Payment
            </Typography>
            <Typography
              variant="body1"
              style={{ width: "30%" }}
              fontWeight={700}
              fontSize={18}
            >
              Withdraw Amount
            </Typography>

            <Typography
              variant="body1"
              style={{ width: "30%" }}
              fontWeight={700}
              fontSize={18}
            >
              Withdraw Photo
            </Typography>
            <Typography
              variant="body1"
              style={{ width: "30%" }}
              fontWeight={700}
              fontSize={18}
            >
              Withdraw Signature
            </Typography>
            <Typography
              variant="body1"
              style={{ width: "30%" }}
              fontWeight={700}
              fontSize={18}
            >
              Withdraw Attachment
            </Typography>
          </Stack>
        </Stack>

        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Typography
                          variant="body1"
                          style={{ width: "30%" }}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {step.label}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ width: "30%" }}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {step.description ? "₱" : ""}
                          {step.description.toLocaleString("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ width: "30%" }}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {step.payment_date}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ width: "30%" }}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {step.actual_amount !== 0
                            ? `₱${step.actual_amount.toLocaleString("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : ""}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ width: "30%" }}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {step.withdraw_amount !== 0
                            ? `₱${step.withdraw_amount.toLocaleString("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : ""}
                        </Typography>

                        <Typography
                          variant="body1"
                          style={{ width: "30%" }}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {step.withdrawn_image !== "" ? (
                            <PhotoProvider>
                              <PhotoView src={step.withdrawn_image}>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={step.withdrawn_image}
                                  sx={{
                                    width: 50,
                                    height: 50,
                                    cursor: "pointer",
                                  }}
                                />
                              </PhotoView>
                            </PhotoProvider>
                          ) : (
                            ""
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ width: "30%" }}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {step.withdrawn_signature !== "" ? (
                            <PhotoProvider maskOpacity={0.1}>
                              <PhotoView src={step.withdrawn_signature}>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={step.withdrawn_signature}
                                  sx={{
                                    width: 50,
                                    height: 50,
                                    cursor: "pointer",
                                  }}
                                />
                              </PhotoView>
                            </PhotoProvider>
                          ) : (
                            ""
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ width: "30%" }}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {step.withdrawn_attachments !== "" ? (
                            <PhotoProvider>
                              <PhotoView src={step.withdrawn_attachments}>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={step.withdrawn_attachments}
                                  sx={{
                                    width: 50,
                                    height: 50,
                                    cursor: "pointer",
                                  }}
                                />
                              </PhotoView>
                            </PhotoProvider>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography sx={{ fontWeight: 700 }}>
              Fully paid and ready for RELOAN
            </Typography>
            {/*<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reloan
            </Button>*/}
          </Paper>
        )}
      </Box>
    );
  }
  return <>{numberOfMonths === 0 ? null : content}</>;
}
