import { Grid, Typography } from "@mui/material";
import React from "react";
import { CurrentLoan } from "../../loans/CurrentLoan";

const ReloanInfo = ({
  data,
  paymentclick,
  isLoading,
  handleDialogOpen,
  type,
}) => {
  return (
    <Grid item xs={12} md={12}>
      <Grid item xs={12} className="gridLabelTitle" marginTop={2}>
        <Typography variant="h6" fontWeight="bold">
          {type ? type : "TRANSACTION INFORMATION"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CurrentLoan
          loans={data}
          paymentclick={paymentclick}
          isLoading={isLoading}
          handleDialogOpen={handleDialogOpen}
          type={type}
        />
      </Grid>
    </Grid>
  );
};
export default ReloanInfo;
