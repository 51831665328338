import * as React from "react";
import {
  LineChart,
  lineElementClasses,
  markElementClasses,
} from "@mui/x-charts/LineChart";
import { Typography } from "@mui/material";

let uData = [];
let pData = [];
let xLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function LineChartWidget({ udata, pdata, isLoading }) {
  if (!isLoading && udata.length > 0) {
    uData = udata;
  }

  if (!isLoading && pdata.length > 0) {
    pData = pdata;
  }
  return (
    <>
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, textAlign: "center", marginBottom: "20px" }}
      >
        Payment Report
      </Typography>
      <LineChart
        height={400}
        series={[
          {
            data: pData,
            color: "#2E96FF",
            label: "Projected Payment Data",
            id: "pvId",
          },
          { data: uData, color: "#02B2AF", label: "Collected Payment Data" },
        ]}
        xAxis={[{ scaleType: "point", data: xLabels }]}
        sx={{
          [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
            strokeWidth: 1,
          },
          ".MuiLineElement-series-pvId": {
            strokeDasharray: "5 5",
          },
          ".MuiLineElement-series-uvId": {
            strokeDasharray: "3 4 5 2",
          },
          [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]:
            {
              fill: "#fff",
            },
          [`& .${markElementClasses.highlighted}`]: {
            stroke: "none",
          },
        }}
      />
    </>
  );
}
