import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;

export default function UserManagementBtn(data) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const options = data.options;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (event) => {
    const btnString = event.currentTarget.textContent;
    switch (btnString) {
      case "View":
        console.log("view");
        break;
      case "Edit":
        data.setOperatorData(data.operatorData);
        data.setModalType("Edit");
        data.setModalOpen(true);
        handleClose();
        break;
      case "Delete":
        //data.dialogMsg(`Are you sure you want to ${btnString} this user?`);
        data.isDialogOpen(true);
        data.setUserId(data.id);
        //data.status(1);
        handleClose();
        break;
      default:
        break;
    }
  };

  const id = data.id;

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => {
          if (data.loanStatus === 0 && option !== "View") {
            return null; // Skip rendering this option
          }
          return (
            <MenuItem
              key={option}
              selected={option === "Pyxis"}
              onClick={handleClickMenu}
              id={id}
            >
              {option}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
