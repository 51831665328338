import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Badge, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import "./modal.css";
//loan form print
import LoanForm from "../../features/printData/LoanForm";
import UserDataForm from "../../features/printData/UserDataForm";
import ComakerDataFrom from "../../features/printData/ComakerDataFrom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  minHeight: 500,
  //overflowY: "auto", // Enable vertical scroll if content overflows
  borderRadius: "10px",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "flow-root",
  maxHeight: "95vh",
  overflowY: "auto",
  "@media (max-width: 600px)": {
    width: "90%",
    overflowY: "auto",
  },
};
export const ModalPrintPreview = ({
  closeModal,
  openModal,
  title,
  type,
  userData,
  comakerData,
  userFetchData,
  watch,
  loanData,
  prevLoanData,
}) => {
  let content = "";
  let content2 = "";
  const componentRef = React.useRef();
  const handlPrint = useReactToPrint({
    content: () => componentRef.current,
  });
  switch (type) {
    case "printPreview":
      content = (
        <LoanForm
          userFetchData={userFetchData}
          watch={watch}
          componentRef={componentRef}
        />
      );

      break;
    case "printUserData":
      content = (
        <UserDataForm
          userData={userData}
          comakerData={comakerData}
          loanData={loanData}
          prevLoanData={prevLoanData}
          componentRef={componentRef}
        />
      );

      content2 = <ComakerDataFrom componentRef={componentRef} />;

      break;
    default:
      //console.log(userCardData, userFetchData, comakerData);
      break;
  }
  const shapeStyles = {
    border: "2px solid",
    width: 50,
    height: 50,
    position: "fixed",
    top: "11%",
    right: "21%",
    cursor: "pointer",
    zIndex: 1000000,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  };
  const shapeCircleStyles = { borderRadius: "50%" };
  const circle = (
    <>
      <Box
        component="span"
        className="floatingPrintBtn"
        sx={{ ...shapeStyles, ...shapeCircleStyles }}
        onClick={handlPrint}
      >
        <PrintTwoToneIcon fontSize="large" color="info" />
      </Box>
    </>
  );
  return (
    <div>
      <Badge
        color="secondary"
        overlap="circular"
        badgeContent=" "
        variant="dot"
      >
        {circle}
      </Badge>

      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalData"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
            }}
            variant="h6"
            component="h2"
          >
            {title}
          </Typography>
          <Box sx={{ marginBottom: "20px" }} />
          {content}
        </Box>
      </Modal>
    </div>
  );
};
