import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

// Adapter setup
const reportsAdapter = createEntityAdapter();
const initialState = reportsAdapter.getInitialState();

// Format date helper function (optional)
const formatDate = (date) => {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, "0");
  const day = `${d.getDate()}`.padStart(2, "0");
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

export const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    paymentReport: builder.query({
      query: ({ datestart, dateend }) => {
        // Ensure dates are in the correct format
        const formattedDatestart = formatDate(datestart);
        const formattedDateend = formatDate(dateend);
        return `/reports/payment?datestart=${encodeURIComponent(
          formattedDatestart
        )}&dateend=${encodeURIComponent(formattedDateend)}`;
      },
    }),
    borrowerList: builder.query({
      query: ({ datestart, dateend }) => {
        const formattedDatestart = formatDate(datestart);
        const formattedDateend = formatDate(dateend);
        return `/reports/borrower?datestart=${encodeURIComponent(
          formattedDatestart
        )}&dateend=${encodeURIComponent(formattedDateend)}`;
      },
    }),
  }),
  overrideExisting: true,
  adapter: reportsAdapter,
  entityTypes: ["Reports"],
});

export const { usePaymentReportQuery, useBorrowerListQuery } = reportApiSlice;
