import { Grid } from "@mui/material";
import React from "react";
import AccordionTransition from "../../components/collapse/Accordion";
import computationMonthly from "../../hooks/useComputation";
import computeFlexible from "../../hooks/useComputeFlexible";
import compute1025th from "../../hooks/useCompute1025th";

const computeResult = (row) => {
  const computationData = {
    amount: row.loans.amount,
    loan_type: row.loan_type,
    interest: row.loans.loan_interest,
    term: row.loans.terms,
    loanId: row.id,
    payment: row.payments,
    effectived_date: row.effectived_date,
    loan_status: row.status,
  };

  if (row.loan_type === 0) {
    return computationMonthly(computationData);
  } else if (row.loan_type === 1) {
    return computeFlexible(computationData);
  } else if (row.loan_type === 2) {
    return compute1025th(computationData);
  }
  return [];
};

export const CurrentLoan = ({
  loans,
  paymentclick,
  isLoading,
  handleDialogOpen,
  type,
}) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          className="currentLoan"
          style={{ paddingleft: "0px !important" }}
        >
          {loans.map((row, index) => {
            console.log(row);
            if (row.status !== undefined && row.status !== 1) {
              const computationResult = computeResult(row);
              return (
                <AccordionTransition
                  key={index}
                  data={row}
                  computationData={computationResult}
                  paymentclick={paymentclick}
                  isLoading={isLoading}
                  handleDialogOpen={handleDialogOpen}
                  type={type}
                />
              );
            }
          })}
        </Grid>
      </Grid>
    </div>
  );
};
