import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAddLoanMutation } from "../../app/api/loans/loanApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "../../app/api/auth/authSlice";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import "./Loan.css";
import dayjs from "dayjs";
import socketService from "../../app/api/ws/initializeWebSocketService";
import BorrowerSignature from "../../components/signature/BorrowerSignature";
import CoMakerSignature from "../../components/signature/CoMakerSignature";
import UploadButton from "../members/membersForm/UploadFileData";
import { ModalData } from "../../components/modals/Modal";

const AddLoan = ({
  loanInfo,
  onClose,
  setSnackBarMsg,
  setSnackBarColor,
  setPopoverOpen,
}) => {
  console.log(loanInfo);
  const [error, setError] = useState(false);
  const interestOptions = [{ title: "7" }, { title: "10" }, { title: "15" }];
  const [loanInterest, setLoanInterest] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(dayjs());
  const [loanType, setLoanType] = useState("");
  const [addLoan, { isLoading }] = useAddLoanMutation();
  const userId = useSelector(selectCurrentUserId);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setmodalTitle] = useState(null);
  const [modalType, setmodalType] = useState(null);
  const [signSrcBorrower, setsignSrcBorrower] = useState("");
  const [signSrcCo, setsignSrcCo] = useState("");
  const [promisoryNote, setPromisoryNote] = useState(null);
  const [certificationDoc, setCertificationDoc] = useState(null);
  const [acknowledgement, setAcknowledgement] = useState(null);

  const handleLoanTypeChange = (event) => {
    setLoanType(event.target.value);
    //setError(event.target.value === "");
  };

  const handleLoanAmountChange = (event) => {
    setLoanAmount(event.target.value);
    //setError(event.target.value === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("loanValue", loanAmount);
    formData.append("loanInterest", loanInterest);
    formData.append("loanTerm", loanTerm);
    formData.append("loanType", loanType);
    formData.append("added_by", loanInfo.loginUser);
    formData.append("user_id", loanInfo.id);
    formData.append("promisory_note", promisoryNote);
    formData.append("certification_doc", certificationDoc);
    formData.append("acknowledgement", acknowledgement);
    formData.append("signSrcBorrower", signSrcBorrower);
    formData.append("signSrcCo", signSrcCo);
    formData.append("borrowerValue", loanInfo.id);
    // Uncomment the try-catch block to handle submission
    try {
      const res = await addLoan(formData);
      if (res) {
        socketService.sendMessage({ type: "refresh" });
        onClose();
        setSnackBarMsg("Successfully added new loan");
        setSnackBarColor("success");
        setPopoverOpen(true);
      }
    } catch (err) {
      console.error(err);
      // Handle error
    }
  };

  const handleFileChange = (event) => {
    console.log(event.target.dataset.value);
    switch (event.target.dataset.value) {
      case "promisory_note":
        setPromisoryNote(event.target.files[0]);
        break;
      case "certification_doc":
        setCertificationDoc(event.target.files[0]);
        break;
      case "acknowledgement":
        setAcknowledgement(event.target.files[0]);
        break;
      default:
        break;
    }
  };

  const handleButtonClick = () => {
    setModalOpen(false);
  };

  const handleSign = (event) => {
    const value = event.target.getAttribute("data-value");
    setmodalType("c" + value);
    setModalOpen(true); // Open the modal
  };

  const handleImg = (imgSrc) => {
    switch (modalType) {
      case "cborrower":
        setsignSrcBorrower(imgSrc);
        break;
      case "ccoMaker":
        setsignSrcCo(imgSrc);
        break;
      default:
        break;
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        mb: 3,
        mt: 2,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography>Payment Effective on</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={effectiveDate}
              label="Payment Effective on"
              onChange={(newValue) => setEffectiveDate(newValue)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>Amount Loan</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Loan Amount"
            type="text"
            onChange={handleLoanAmountChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>Loan Interest</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            id="loanInterest"
            freeSolo
            options={interestOptions.map((option) => option.title)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Monthly Interest"
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            )}
            onChange={(event, newValue) => setLoanInterest(newValue || "")}
            value={loanInterest}
            inputValue={loanInterest}
            onInputChange={(event, newInputValue) =>
              setLoanInterest(newInputValue || "")
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>Payment Term</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Payment Term"
            type="number"
            onChange={(e) => setLoanTerm(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>Loan Type</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="loan-type-label">Loan Type</InputLabel>
            <Select
              labelId="loan-type-label"
              id="loan-type-select"
              value={loanType}
              label="Loan Type"
              onChange={handleLoanTypeChange}
            >
              <MenuItem value={0}>Monthly</MenuItem>
              <MenuItem value={1}>Flexible</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <UploadButton
              selectedImage={promisoryNote}
              handleFileChange={handleFileChange}
              uploadValue="promisory_note"
              title="Promisory Note"
            />

            <UploadButton
              selectedImage={certificationDoc}
              handleFileChange={handleFileChange}
              uploadValue="certification_doc"
              title="Certification Docutment"
            />

            <UploadButton
              selectedImage={acknowledgement}
              handleFileChange={handleFileChange}
              uploadValue="acknowledgement"
              title="Acknowledgement"
            />
          </Stack>

          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            {loanType === 1 ? (
              <BorrowerSignature
                signSrcBorrower={signSrcBorrower}
                handleSign={handleSign}
              />
            ) : (
              <>
                <BorrowerSignature
                  signSrcBorrower={signSrcBorrower}
                  handleSign={handleSign}
                />
                <CoMakerSignature
                  signSrcCo={signSrcCo}
                  handleSign={handleSign}
                />
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} textAlign={"right"}>
          <LoadingButton
            color="secondary"
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type="button"
            onClick={handleSubmit}
            sx={{ fontSize: "1rem" }}
          >
            <span>Confirm</span>
          </LoadingButton>
        </Grid>
      </Grid>
      {modalOpen && (
        <ModalData
          closeModal={handleButtonClick}
          onSubmit={handleButtonClick}
          onCancel={handleButtonClick}
          openModal={modalOpen}
          title={modalTitle}
          type={modalType}
          profileName=""
          signatureImg={handleImg}
        />
      )}
    </Paper>
  );
};

export default AddLoan;
