// pageRoutes.js
import React from "react";
import CustomRoute from "./CustomRoutes";
import Login from "../features/login/Login";
import Dashboard from "../features/dashboard/Dashboard";
import AdminDashboard from "../features/admin/AdminDashboard";
import Members from "../features/members/Members";
import ManageUsers from "../features/admin/ManageUsers";
import ManageReports from "../features/admin/ManageReports";
import BorrowersList from "../features/admin/borrowers/BorrowersList";
import CoMakerList from "../features/admin/comaker/CoMakerList";
import InvestorsList from "../features/admin/investors/InvestorsList";
import UpdateCoMaker from "../features/admin/comaker/UpdateCoMaker";
import Page404 from "../features/404/Page404";
import { ROLES } from "../config/roles";

const allRoles = Object.values(ROLES);

const router = [
  {
    path: "/login",
    element: <Login />,
    title: "Login Page",
    isHidden: true,
  },
  {
    path: "/",
    element: (
      <CustomRoute
        component={AdminDashboard}
        allowedRoles={[ROLES.Admin]}
        title="Home"
      />
    ),
    title: "Home",
    isHidden: false,
  },
  {
    path: "/operator-dashboard",
    element: (
      <CustomRoute
        component={Dashboard}
        allowedRoles={[ROLES.Admin, ROLES.Manager]}
        title="Home"
      />
    ),
    title: "Operator",
    isHidden: false,
  },
  {
    path: "/manage-borrowers",
    element: (
      <CustomRoute
        component={BorrowersList}
        allowedRoles={[ROLES.Admin, ROLES.Manager]}
        title="Manage Borrowers"
      />
    ),
    isHidden: false,
    title: "Manage Borrowers",
  },
  {
    path: "/manage-comaker",
    element: (
      <CustomRoute
        component={CoMakerList}
        allowedRoles={[ROLES.Admin]}
        title="Manage Comaker"
      />
    ),
    title: "Manage Comaker",
    isHidden: false,
  },
  {
    path: "/manage-comaker/edit/:id",
    element: (
      <CustomRoute
        component={UpdateCoMaker}
        allowedRoles={[ROLES.Admin]}
        title="Manage Comaker"
      />
    ),
    title: "Manage Comaker",
    isHidden: true,
  },
  {
    path: "/manage-users",
    element: (
      <CustomRoute
        component={ManageUsers}
        allowedRoles={[ROLES.Admin]}
        title="Manage Operators"
      />
    ),
    title: "Manage Operators",
    isHidden: false,
  },
  {
    path: "/manage-investors",
    element: (
      <CustomRoute
        component={InvestorsList}
        allowedRoles={[ROLES.Admin]}
        title="Manage Investors"
      />
    ),
    title: "Manage Investors",
    isHidden: true,
  },
  {
    path: "/reports",
    element: (
      <CustomRoute
        component={ManageReports}
        allowedRoles={[ROLES.Admin]}
        title="Reports"
      />
    ),
    title: "Reports",
    isHidden: false,
  },
  {
    path: "/Members/:type",
    element: (
      <CustomRoute
        component={Members}
        allowedRoles={[ROLES.Admin, ROLES.Manager]}
        title="Members"
      />
    ),
    title: "Members",
    isHidden: true,
  },
  {
    path: "/Members/:type/:id",
    element: (
      <CustomRoute
        component={Members}
        allowedRoles={[ROLES.Admin, ROLES.Manager]}
        title="Members"
      />
    ),
    title: "Members",
    isHidden: true,
  },
  {
    path: "/*",
    element: (
      <CustomRoute
        component={Page404}
        title="Members"
        allowedRoles={allRoles}
      />
    ),
    isHidden: true,
  },
];

export default router;
