import React, { useCallback, useEffect, useMemo } from "react";
import ReloanInfo from "./reloanForm/ReloanInfo";
import { useGetUsersQuery } from "../../app/api/users/usersApiSlice";
import { useParams } from "react-router-dom";
import MembersInfo from "./membersInfo/MembersInfo";
import { MemberModal } from "../../components/modals/MemberModals";
import { ModalData } from "../../components/modals/Modal";
import { Paper } from "@mui/material";
import ResponsiveDialog from "../../components/modals/Dialog";
import SharedDialog from "../../components/modals/SharedDialog";
import dayjs from "dayjs";
import { useApproveLoanMutation } from "../../app/api/loans/loanApiSlice";
import socketService from "../../app/api/ws/initializeWebSocketService";
import { selectCurrentUserId } from "../../app/api/auth/authSlice";
import { useSelector } from "react-redux";
import { CustomSnackbar } from "../../components/popover/Snackbar";

function MemberLoans() {
  const { id } = useParams();
  const queryOptions = useMemo(
    () => ({
      skip: !id,
      refetchOnWindowFocus: false,
      refetchOnMountOrArgChange: true,
    }),
    [id]
  );

  const { data, isLoading } = useGetUsersQuery(id || "", queryOptions);

  const [loanData, setLoanData] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [comaker, setComaker] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openMemberModal, setOpenMemberModal] = React.useState(false);
  const [modalValue, setmodalValue] = React.useState("");
  const [modalTitle, setmodalTitle] = React.useState("");
  const [modalType, setmodalType] = React.useState("");
  const [modalMemberType, setModalMemberType] = React.useState("");
  const [modalData, setModalData] = React.useState("");
  const [bodyContent, setBodyContent] = React.useState("");
  const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [effectiveDate, setEffectiveDate] = React.useState(dayjs());
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [dialogChoice, setDialogChoice] = React.useState("");
  const [dialogMsg, setDialogMsg] = React.useState("");
  const [loanId, setLoanId] = React.useState("");
  const user_id = useSelector(selectCurrentUserId);
  const [approveLoan] = useApproveLoanMutation();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [snackBarColor, setSnackBarColor] = React.useState("");

  useEffect(() => {
    if (id && !isLoading) {
      data.ids.forEach((userId) => {
        const user = data.entities[userId];
        if (user) {
          // Sort loans by status in ascending order
          const sortedLoans = [...user.loans].sort((a, b) => {
            if (a.status < b.status) return -1;
            if (a.status > b.status) return 1;
            return 0;
          });

          // Update loan data state
          setLoanData(sortedLoans);

          // Update user data state
          setUserData({
            id: user.id,
            name: `${user.borrower.firstname} ${user.borrower.middlename} ${user.borrower.lastname}`,
            address: user.borrower.address,
            image: user.attachments.image_profile,
            signature: user.attachments.signature,
            member_group: user.borrower.membersgroup,
            contact_number: user.contact.phonenumber,
            civil_status: user.borrower.civil_status,
            rank: user.borrower.rank,
            unit_assign: user.borrower.unitassign,
            serial: user.borrower.serial,
            telephone_number: user.contact.telephonenumber,
            email: user.contact.email,
            spouseName: user.spouse
              ? `${user.spouse.firstname} ${user.spouse.middlename} ${user.spouse.lastname}`
              : null,
          });

          // Update co-maker data state
          setComaker({
            name: `${user.comaker.name.firstname} ${user.comaker.name.middlename} ${user.comaker.name.lastname}`,
            address: user.comaker.name.address,
            image: user.comaker.attachments.image,
            signature: user.comaker.attachments.signature,
          });
        }
      });
    }
  }, [id, isLoading, data]);

  const handlePaymentModal = (dataPayment) => {
    setmodalValue(dataPayment);
    setmodalTitle(dataPayment.btnValue);
    setModalData(dataPayment);
    switch (dataPayment.type) {
      case "coMaker":
      case "borrower":
      case "camera":
      case "cameraComaker":
        setmodalType("c" + dataPayment.type);
        setOpenModal(true);
        break;
      case "Add Payment":
        setModalMemberType("Add Payment");
        setOpenMemberModal(true);
        setmodalTitle("Add Payment");
        break;
      case "Reloan":
        setModalMemberType("Reloan");
        setOpenMemberModal(true);
        setmodalTitle("Reloan Application");
        break;
      case "newLoan":
      case "view more..":
        setModalMemberType("f" + dataPayment.type);
        setmodalTitle("Add new Loan");
        setOpenMemberModal(true);
        break;
      case "Withdraw":
        setModalMemberType("Withdraw");
        setmodalTitle("Withdraw");
        setOpenMemberModal(true);
        break;
      default:
        break;
    }
  };

  const handleDialogOpen = (e) => {
    setLoanId(e.currentTarget.dataset.id);
    setEffectiveDate(dayjs(e.currentTarget.dataset.effective));
    if (parseInt(e.currentTarget.value) === 1) {
      setDialogMsg("Are you sure you want to approve this loan?");
      setStatus(1);
      setIsDialogOpen(true);
    } else {
      setDialogMsg("Are you sure you want to reject this loan?");
      setStatus(0);
      setIsDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen2(false);
    setDialogChoice("");
  };

  const handleDialogYes = useCallback(async () => {
    try {
      const statusData = {
        status: status === 1 ? 2 : 1,
        added_by: user_id,
        loan_id: loanId,
        effectiveDate: effectiveDate,
      };
      const result = await approveLoan(statusData).unwrap();
      if (result) {
        socketService.sendMessage({ type: "refresh" });
        if (status === 1) {
          setSnackBarMsg("Successfully approved loan");
          setSnackBarColor("success");
          setPopoverOpen(true);
          setIsDialogOpen2(false);
          setDialogChoice("");
        } else {
          setSnackBarMsg("Successfully rejected loan");
          setSnackBarColor("error");
          setPopoverOpen(true);
          setIsDialogOpen2(false);
          setDialogChoice("");
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [status, user_id, loanId, effectiveDate, approveLoan]);

  useEffect(() => {
    if (parseInt(dialogChoice) === 1) {
      if (status === 0) {
        handleDialogYes();
      } else {
        setIsDialogOpen2(true);
      }
    }
  }, [dialogChoice, status, handleDialogYes]);

  const handleCloseSnackbar = () => {
    setPopoverOpen(false);
  };

  const handleCloseModal = () => {
    setOpenMemberModal(false);
  };

  return (
    <div>
      <MembersInfo
        userData={userData}
        comaker={comaker}
        loanData={loanData}
        isLoading={isLoading}
        handleCreateLoan={handlePaymentModal}
        loginUser={id}
      />
      <Paper elevation={3} style={{ padding: "20px", marginTop: "10px" }}>
        <ReloanInfo
          data={loanData}
          paymentclick={handlePaymentModal}
          isLoading={isLoading}
          handleDialogOpen={handleDialogOpen}
          type="Loan Transaction Information"
        />
      </Paper>
      {openModal && (
        <ModalData
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          type={modalType}
        />
      )}
      {openMemberModal && (
        <MemberModal
          openModal={openMemberModal}
          closeModal={() => setOpenMemberModal(false)}
          type={modalMemberType}
          reqValue={modalData}
          BodyContent={bodyContent}
          title={modalTitle}
          setSnackBarColor={setSnackBarColor}
          setSnackBarMsg={setSnackBarMsg}
          setPopoverOpen={setPopoverOpen}
        />
      )}
      {isDialogOpen && (
        <SharedDialog
          isDialogOpen={setIsDialogOpen}
          isOpen={isDialogOpen}
          title={"Borrower"}
          bodyContent={dialogMsg}
          setDialogChoice={setDialogChoice}
        />
      )}
      {isDialogOpen2 && (
        <ResponsiveDialog
          dialogOpen={isDialogOpen2}
          handleDialogYes={handleDialogYes}
          handleDialogClose={handleDialogClose}
          setEffectiveDate={setEffectiveDate}
          effectiveDate={effectiveDate}
          dialogType={dialogChoice}
        />
      )}
      {popoverOpen && (
        <CustomSnackbar
          openSnackBar={popoverOpen}
          closeSnackBar={handleCloseSnackbar}
          messageData={snackBarMsg}
          colorVariant={snackBarColor}
        />
      )}
    </div>
  );
}

export default MemberLoans;
