import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import router from "../../utils/pageRoutes";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import HomeIcon from "@mui/icons-material/Home";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EngineeringIcon from "@mui/icons-material/Engineering";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import useAuth from "../../hooks/useAuth";

function iconSet(title, selected) {
  let color = selected ? "primary.main" : "inherit";
  switch (title) {
    case "Home":
      return <HomeIcon sx={{ color: color }} />;
      break;
    case "Manage Borrowers":
      return <Diversity3Icon sx={{ color: color }} />;
      break;
    case "Manage Users":
      return <GroupAddIcon sx={{ color: color }} />;
      break;
    case "Operator":
      return <RoomPreferencesIcon sx={{ color: color }} />;
      break;
    case "Reports":
      return <SummarizeIcon sx={{ color: color }} />;
      break;
    case "Manage Comaker":
      return <Diversity2Icon sx={{ color: color }} />;
      break;
    case "Manage Investors":
      return <RequestQuoteIcon sx={{ color: color }} />;
      break;
    case "Manage Operators":
      return <EngineeringIcon sx={{ color: color }} />;
      break;
    default:
      return <InboxIcon sx={{ color: color }} />;
      break;
  }
}

const SideNavGen = ({ openStateData }) => {
  const { roles } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Filter routes based on allowed roles
  const allowedRoutes = router.filter((route) =>
    route.element.props.allowedRoles?.some((role) => roles.includes(role))
  );

  return (
    <List>
      {allowedRoutes.map((route, index) => {
        const selected = location.pathname === route.path;

        return (
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            key={index}
            style={{ display: route.isHidden ? "none" : "block" }}
            onClick={() => {
              navigate(route.path);
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: openStateData ? "initial" : "center",
                px: 2.5,
                backgroundColor: selected ? "rgba(0, 0, 0, 0.08)" : "inherit", // Highlight selected item
                "&:hover": {
                  color: "primary.main", // Mouse hover color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: openStateData ? 3 : "auto",
                  justifyContent: "center",
                  color: selected ? "primary.main" : "inherit", // Change icon color for selected item
                  "&:hover": {
                    color: "primary.main", // Mouse hover color
                  },
                }}
              >
                {iconSet(route.title, selected)}
              </ListItemIcon>
              <ListItemText
                primary={route.title}
                sx={{ opacity: openStateData ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SideNavGen;
