import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import dayjs from "dayjs";

function ccyFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export default function ReportTable({
  rows,
  totalPaid,
  interestAmountTotal,
  withdrawnTotal,
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Payment Date</TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              {" "}
              Name{" "}
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              Principal Amount
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              Interest Amount
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              Paid Amount
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              Withdraw
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              Interest Amount
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              Remaining Amount
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{dayjs(row.date).format("MMM D, YYYY")}</TableCell>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">₱{ccyFormat(row.pamount)}</TableCell>
              <TableCell align="right">₱{ccyFormat(row.iamount)}</TableCell>
              <TableCell align="right">₱{ccyFormat(row.paid)}</TableCell>
              <TableCell align="right">₱{ccyFormat(row.withdrawn)}</TableCell>
              <TableCell align="right">{row.interest}%</TableCell>
              <TableCell align="right">₱{ccyFormat(row.remaining)}</TableCell>
              <TableCell align="right">
                {row.status === 1 ? "Withdrawn" : "Paid"}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3} sx={{ fontWeight: 700 }}>
              Total
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              ₱{ccyFormat(interestAmountTotal)}
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              ₱{ccyFormat(totalPaid)}
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              ₱{ccyFormat(withdrawnTotal)}
            </TableCell>
            <TableCell colSpan={1}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
