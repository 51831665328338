import { Button, Grid, Stack } from "@mui/material";
import React from "react";
const BorrowerSignContent = ({ signSrcBorrower, handleSign }) => {
  return (
    <Grid
      container
      item
      xs={12}
      textAlign={"center"}
      alignContent={"center"}
      justifyContent={"center"}
      display={"flex"}
      md={12}
    >
      <Grid item xs={4}>
        <Stack textAlign={"center"}>
          {signSrcBorrower ? (
            <img
              src={signSrcBorrower}
              style={{ width: "100%", height: "100px" }}
              alt="borrower signature"
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "#FFFFF",
              }}
            ></div>
          )}
          <div style={{ borderBottom: "1px solid", width: "100%" }}></div>
          Borrower Signature
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Button data-value="borrower" variant="contained" onClick={handleSign}>
          Sign
        </Button>
      </Grid>
    </Grid>
  );
};

export default BorrowerSignContent;
