import { Box, Button, Grid } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import React,{useRef} from 'react'

export const Signature = ({onClose,onConfirm}) => {
    const signatureRef = useRef(null);
    const handleClearSignature = () => {
        signatureRef.current.clear();
    };
    const handleSubmit = ()=>{
        const imgSrc = signatureRef.current.toDataURL();
        onConfirm(imgSrc);
        onClose();
    }
  return (
    <>
        <Grid item xs={12}>
            <Box border={1} borderRadius={5} textAlign="center" p={2}>
                <SignatureCanvas
                ref={signatureRef}
                penColor="black"
                canvasProps={{width: 600, height: 200, className: 'signatureCanvas'}}
                />
                <Button onClick={handleClearSignature}>Clear Signature</Button>
            </Box>
        </Grid>
        <Grid item xs={12} textAlign={'right'} marginTop={5}>
            <Button variant="contained" color="success" onClick={handleSubmit}>Confirm</Button>
            <Button variant='contained' color='error' onClick = {()=>onClose()} sx={{ marginLeft: 2 }}>Close</Button>
        </Grid>
    </>
  )
}
