import React, { useState, useRef } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./style.css";
import {
  format,
  addDays,
  subDays,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
} from "date-fns";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const DateRangePickerComponent = ({ setDateRange, dateRange }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  const handleRangeChange = (item) => {
    setDateRange([item.selection]);
  };

  const handleInputClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const setToday = () => {
    const today = new Date();
    setDateRange([{ startDate: today, endDate: today, key: "selection" }]);
  };

  const setYearly = () => {
    setDateRange([
      {
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
        key: "selection",
      },
    ]);
  };

  const setLast7Days = () => {
    setDateRange([
      {
        startDate: subDays(new Date(), 6),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  const setThisMonth = () => {
    setDateRange([
      {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
        key: "selection",
      },
    ]);
  };

  const setThisWeek = () => {
    setDateRange([
      {
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
        key: "selection",
      },
    ]);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth: "700px",
            textAlign: "center",
          }}
        >
          <TextField
            ref={inputRef}
            label="Select Date Range"
            sx={{
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="date-range-picker"
            value={`${format(dateRange[0].startDate, "MM/dd/yyyy")} - ${format(
              dateRange[0].endDate,
              "MM/dd/yyyy"
            )}`}
            onClick={handleInputClick}
            fullWidth
            readOnly
          />
          {open && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 1,
                mt: 1,
                p: 2,
                backgroundColor: "white",
                boxShadow: 3,
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
              }}
            >
              <DateRange
                editableDateInputs={true}
                onChange={handleRangeChange}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                shownDate={dateRange[0].startDate}
                months={2}
                direction="horizontal"
                style={{ width: "100%", minWidth: "250px" }}
              />
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  mt: 2,
                  justifyContent: "center",
                }}
              >
                <Button variant="outlined" onClick={setToday}>
                  Today
                </Button>
                <Button variant="outlined" onClick={setLast7Days}>
                  Last 7 Days
                </Button>
                <Button variant="outlined" onClick={setThisWeek}>
                  This Week
                </Button>
                <Button variant="outlined" onClick={setThisMonth}>
                  This Month
                </Button>
                <Button variant="outlined" onClick={setYearly}>
                  Yearly
                </Button>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default DateRangePickerComponent;
