import { Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BorrowerSignature from "../../../components/signature/BorrowerSignature";
import CoMakerSignature from "../../../components/signature/CoMakerSignature";
import UploadButton from "../membersForm/UploadFileData";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useAddLoanMutation } from "../../../app/api/loans/loanApiSlice";
import { selectCurrentUserId } from "../../../app/api/auth/authSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetUsersQuery } from "../../../app/api/users/usersApiSlice";
import { ModalData } from "../../../components/modals/Modal";
import socketService from "../../../app/api/ws/initializeWebSocketService";

const ReloanData = ({
  onClose,
  reqValue,
  setSnackBarMsg,
  setSnackBarColor,
  setPopoverOpen,
}) => {
  const { id } = useParams();
  const [loanValue, setLoanValue] = React.useState(
    reqValue.computed.remaining_balance
  );
  const [loanInterest, setLoanInterest] = React.useState(
    reqValue.computed.loanInterest
  );
  const [loanType, setLoanType] = React.useState(reqValue.computed.loanType);
  const [loanTerm, setLoanTerm] = React.useState(reqValue.computed.loanTerm);
  const [addLoan, { isLoading }] = useAddLoanMutation();
  const user_id = useSelector(selectCurrentUserId);
  const { refetch } = useGetUsersQuery(id ? id : "");
  const [promisoryNote, setPromisoryNote] = React.useState(null);
  const [certificationDoc, setCertificationDoc] = React.useState(null);
  const [acknowledgement, setAcknowledgement] = React.useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setmodalTitle] = useState(null);
  const [modalType, setmodalType] = useState(null);
  const [signSrcBorrower, setsignSrcBorrower] = useState("");
  const [signSrcCo, setsignSrcCo] = useState("");

  const handleFileChange = (event) => {
    console.log(event.target.dataset.value);
    switch (event.target.dataset.value) {
      case "promisory_note":
        setPromisoryNote(event.target.files[0]);
        break;
      case "certification_doc":
        setCertificationDoc(event.target.files[0]);
        break;
      case "acknowledgement":
        setAcknowledgement(event.target.files[0]);
        break;
      default:
        break;
    }
  };

  const handleButtonClick = () => {
    setModalOpen(false);
  };

  const handleSign = (event) => {
    const value = event.target.getAttribute("data-value");
    setmodalType("c" + value);
    setModalOpen(true); // Open the modal
  };

  const handleImg = (imgSrc) => {
    switch (modalType) {
      case "cborrower":
        setsignSrcBorrower(imgSrc);
        break;
      case "ccoMaker":
        setsignSrcCo(imgSrc);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("loanValue", loanValue);
      formData.append("loanInterest", loanInterest);
      formData.append("loanTerm", reqValue.computed.loanTerm);
      formData.append("loanType", reqValue.computed.loanType);
      formData.append("added_by", user_id);
      formData.append("user_id", reqValue.borrower_id);
      formData.append("promisory_note", promisoryNote);
      formData.append("certification_doc", certificationDoc);
      formData.append("acknowledgement", acknowledgement);
      formData.append("signSrcBorrower", signSrcBorrower);
      formData.append("signSrcCo", signSrcCo);
      formData.append("borrowerValue", reqValue.borrower_id);
      formData.append("loanId", reqValue.computed.loanId);
      formData.append("status", 3);

      const res = await addLoan(formData);
      if (res) {
        socketService.sendMessage({ type: "refresh" });
        onClose();
        setPopoverOpen(true);
        setSnackBarMsg("Successfully Reloan");
        setSnackBarColor("success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  let content = (
    <>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Stack direction={"column"} spacing={2}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ display: "flex", alignItems: "center" }}
            spacing={2}
          >
            <Typography sx={{ width: "150px" }}>Loan Amount:</Typography>
            <TextField
              label="Amount"
              type="text"
              value={loanValue}
              onChange={(e) => setLoanValue(e.target.value)}
              style={{ width: "100%" }}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ display: "flex", alignItems: "center" }}
            spacing={2}
          >
            <Typography sx={{ width: "150px" }}>Loan Interest:</Typography>
            <TextField
              label="Interest"
              type="text"
              value={loanInterest}
              onChange={(e) => setLoanInterest(e.target.value)}
              style={{ width: "100%" }}
            />
          </Stack>
          {parseInt(loanType) === 0 && (
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
              spacing={2}
            >
              <Typography sx={{ width: "150px" }}>Loan Term:</Typography>
              <TextField
                label="Loan Term"
                type="text"
                value={loanTerm}
                onChange={(e) => setLoanTerm(e.target.value)}
                style={{ width: "100%" }}
              />
            </Stack>
          )}
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <UploadButton
              selectedImage={promisoryNote}
              handleFileChange={handleFileChange}
              uploadValue="promisory_note"
              title="Promisory Note"
            />

            <UploadButton
              selectedImage={certificationDoc}
              handleFileChange={handleFileChange}
              uploadValue="certification_doc"
              title="Certification Docutment"
            />

            <UploadButton
              selectedImage={acknowledgement}
              handleFileChange={handleFileChange}
              uploadValue="acknowledgement"
              title="Acknowledgement"
            />
          </Stack>

          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <BorrowerSignature
              signSrcBorrower={signSrcBorrower}
              handleSign={handleSign}
            />
            <CoMakerSignature signSrcCo={signSrcCo} handleSign={handleSign} />
          </Stack>
        </Stack>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          mt: 2,
          width: "100%",
          justifyContent: "right",
          textAlign: "right",
        }}
      >
        <LoadingButton
          color="secondary"
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          type="button"
          onClick={handleSubmit}
          sx={{ fontSize: "1rem" }}
        >
          <span>Confirm</span>
        </LoadingButton>
      </Paper>
      {modalOpen && (
        <ModalData
          closeModal={handleButtonClick}
          onSubmit={handleButtonClick}
          onCancel={handleButtonClick}
          openModal={modalOpen}
          title={modalTitle}
          type={modalType}
          profileName=""
          signatureImg={handleImg}
        />
      )}
    </>
  );
  return content;
};

export default ReloanData;
