import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddLoan from "../../features/loans/AddLoan";
import AddPayment from "../../features/loans/AddPayment";
import { Typography } from "@mui/material";

import WithdrawForm from "../../features/members/withdraw/WithdrawForm";
//v1 of forms
import PaymentFormv1 from "../../features/loans/paymentForm/PaymentFormv1";
import WithdrawData from "../../features/members/withdraw/WithdrawData";
import LoanEdit from "../../features/loans/loanForms/LoanEdit";
import ReloanData from "../../features/members/reloanForm/ReloanData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  minHeight: 500,
  //overflowY: "auto", // Enable vertical scroll if content overflows
  borderRadius: "10px",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "flow-root",
  maxHeight: "95vh",
  overflowY: "auto",
  "@media (max-width: 600px)": {
    width: "90%",
    overflowY: "auto",
  },
};
export const MemberModal = ({
  closeModal,
  openModal,
  title,
  type,
  user_id,
  reqValue,
  BodyContent,
  setSnackBarMsg,
  setSnackBarColor,
  setPopoverOpen,
}) => {
  let content = "";
  switch (type) {
    case "fnewLoan":
      content = (
        <AddLoan
          loanInfo={reqValue}
          onClose={closeModal}
          setSnackBarMsg={setSnackBarMsg}
          setSnackBarColor={setSnackBarColor}
          setPopoverOpen={setPopoverOpen}
        />
      );
      break;
    case "Add Payment":
      content = (
        <PaymentFormv1
          data={reqValue}
          onClose={closeModal}
          setSnackBarMsg={setSnackBarMsg}
          setSnackBarColor={setSnackBarColor}
          setPopoverOpen={setPopoverOpen}
        />
      );
      break;
    case "Withdraw":
      content = (
        <WithdrawData
          data={reqValue}
          onClose={closeModal}
          setSnackBarMsg={setSnackBarMsg}
          setSnackBarColor={setSnackBarColor}
          setPopoverOpen={setPopoverOpen}
        />
      );
      break;
    case "EditLoan":
      content = (
        <LoanEdit
          data={reqValue}
          onClose={closeModal}
          setSnackBarMsg={setSnackBarMsg}
          setSnackBarColor={setSnackBarColor}
          setPopoverOpen={setPopoverOpen}
        />
      );
      break;
    case "Reloan":
      content = (
        <ReloanData
          reqValue={reqValue}
          onClose={closeModal}
          setSnackBarMsg={setSnackBarMsg}
          setSnackBarColor={setSnackBarColor}
          setPopoverOpen={setPopoverOpen}
        />
      );
      break;
    case "fview more..":
      console.log("view more");
      break;
    default:
      break;
  }
  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalData"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
            }}
            variant="h6"
            component="h2"
          >
            {title}
          </Typography>
          <Box sx={{ marginBottom: "20px" }} />
          {content}
        </Box>
      </Modal>
    </div>
  );
};
