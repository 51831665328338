import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLoginUsers: builder.query({
      query: () => "/users",
      keepUnusedDataFor: 5,
      providesTags: [{ type: "Users", id: "Operators" }],
    }),
    getUsers: builder.query({
      query: (id) => `/members/${id}`,
      transformResponse: (responseData) => {
        if (!responseData) {
          return initialState;
        }
        return usersAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, arg) => [{ type: "Users", id: "Members" }],
      keepUnusedDataFor: 0,
    }),
    addUser: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/members",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [
        { type: "Users", id: "Members" },
        { type: "Users", id: "Payments" },
      ],
    }),
    getCardInfo: builder.query({
      query: (id) => `/card-info/${id}`,
    }),
    getCoMaker: builder.query({
      query: (id) => `/co-maker/${id}`,
      providesTags: [{ type: "Users", id: "comaker" }],
    }),
    getPayments: builder.query({
      query: (id) => `/payments/${id}`,
      providesTags: [{ type: "Users", id: "Payments" }],
      keepUnusedDataFor: 0,
    }),
    deleteBorrower: builder.mutation({
      query: ({ id, status, type }) => {
        return {
          url: `/delete/${id}/${status}/${type}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Users", id: "Members" }],
    }),
    addOperator: builder.mutation({
      query: (formData) => ({
        url: "/users",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Users", id: "Operators" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useGetCardInfoQuery,
  useGetCoMakerQuery,
  useGetPaymentsQuery,
  useGetLoginUsersQuery,
  useDeleteBorrowerMutation,
  useAddOperatorMutation,
} = usersApiSlice;
