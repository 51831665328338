import React from "react";
import MembersData from "./MembersData";
import MemberLoans from "./MemberLoans";
import { useParams } from "react-router-dom";

function Members() {
  const { type } = useParams();
  let content = null;
  if (type === "add" || type === "view" || type === "edit") {
    content = <MembersData />;
  } else if (type === "loans") {
    content = <MemberLoans />;
  }
  return content;
}

export default Members;
