import React from "react";
import { useBorrowerListQuery } from "../../app/api/reports/reportApiSlice";
import BorrowerTable from "./reportsTable/BorrowerTable";
import { Paper } from "@mui/material";
import { CSVLink } from "react-csv";

function BorrowerList({ dateRange, triggerReport }) {
  const { data, error, isLoading } = useBorrowerListQuery(dateRange, {
    skip: !triggerReport,
  });

  let rows = [];
  let rowsCSV = [];
  if (!isLoading && data) {
    data.forEach((element) => {
      rows.push({
        id: element.id,
        name:
          element.firstname + " " + element.middlename + " " + element.lastname,
        address: element.address,
        memberGroup: element.memberGroup,
        civilStatus: element.civilStatus,
        email: element.email,
        phoneNumber: element.phoneNumber,
        telephoneNumber: element.telephoneNumber,
        coMakerName:
          element.coMakerFirstName +
          " " +
          element.coMakerMiddleName +
          " " +
          element.coMakerLastName,
        totalLoans: element.loanCount,
        isWithBorrowerId: element.isWithBorrowerId ? "Yes" : "No",
        isWithBorrowerSignature: element.isWithBorrowerSignature ? "Yes" : "No",
        isWithBorrowerProfileImg: element.isWithBorrowerProfileImg
          ? "Yes"
          : "No",
        isWithCoMakerId: element.isWithCoMakerId ? "Yes" : "No",
        isWithCoMakerSignature: element.isWithCoMakerSignature ? "Yes" : "No",
        isWithCoMakerProfileImg: element.isWithCoMakerProfileImg ? "Yes" : "No",
        spouseFirstName: element.spouseFirstName,
        spouseLastName: element.spouseLastName,
        spouseMiddleName: element.spouseMiddleName,
      });

      rowsCSV.push({
        id: element.id,
        borrowerFirstName: element.firstname,
        borrowerLastName: element.lastname,
        borrowerMiddleName: element.middlename,
        email: element.email,
        address: element.address,
        phoneNumber: element.phoneNumber,
        telephoneNumber: element.telephoneNumber,
        memberGroup: element.memberGroup,
        civilStatus: element.civilStatus,
        spouseFirstName: element.spouseFirstName,
        spouseLastName: element.spouseLastName,
        spouseMiddleName: element.spouseMiddleName,
        coMakerFirstName: element.coMakerFirstName,
        coMakerLastName: element.coMakerLastName,
        coMakerMiddleName: element.coMakerMiddleName,
        totalLoans: element.loanCount,
        isWithBorrowerId: element.isWithBorrowerId ? "Yes" : "No",
        isWithBorrowerSignature: element.isWithBorrowerSignature ? "Yes" : "No",
        isWithBorrowerProfileImg: element.isWithBorrowerProfileImg
          ? "Yes"
          : "No",
        isWithCoMakerId: element.isWithCoMakerId ? "Yes" : "No",
        isWithCoMakerSignature: element.isWithCoMakerSignature ? "Yes" : "No",
        isWithCoMakerProfileImg: element.isWithCoMakerProfileImg ? "Yes" : "No",
      });
    });
  }

  let content = "";

  if (triggerReport) {
    content = (
      <>
        <Paper
          elevation={3}
          style={{ padding: "20px", marginBottom: "10px", textAlign: "right" }}
        >
          <CSVLink
            data={rowsCSV}
            filename={"borrower_list.csv"}
            style={{
              color: "rgb(0 60 253 / 87%)",
              textDecoration: "none",
            }}
          >
            Download CSV
          </CSVLink>
        </Paper>
        <BorrowerTable rows={rows} />
      </>
    );
  } else {
    content = <div>Click on the button to generate report</div>;
  }

  return isLoading ? <div>Loading...</div> : content;
}

export default BorrowerList;
