import React from "react";
import { useGetCoMakerQuery } from "../../../app/api/users/usersApiSlice";
import { useParams } from "react-router-dom";
import { Grid, Stack, Typography, TextField, Button } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { ModalData } from "../../../components/modals/Modal";
export default function UpdateCoMaker() {
  const { type, id } = useParams();
  const { data, isLoading, isError } = useGetCoMakerQuery(id);
  const { control, handleSubmit, setValue, watch } = useForm();
  const [comakerSrc, setcomakerSrc] = React.useState("");

  console.log(data);
  /*const handlecoMakerChange = (event, value) => {
    console.log(coMakerOption.find((option) => option.title === value).lname);
    setValue(
      "middlenameCo",
      coMakerOption.find((option) => option.title === value).mname
    );
    setValue(
      "lastnameCo",
      coMakerOption.find((option) => option.title === value).lname
    );
    setValue(
      "addressCo",
      coMakerOption.find((option) => option.title === value).address
    );
    setsignSrcCo(
      coMakerOption.find((option) => option.title === value).signature
    );
    setcomakerSrc(coMakerOption.find((option) => option.title === value).image);
    setcoMakerValue(value);
  };*/
  const handleSign = (e) => {
    console.log(e.currentTarget.dataset.value);
  };

  return (
    <Grid item xs={12} md={12}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12} className="gridLabelTitle">
        <Typography variant="h6" fontWeight="bold">
          CO-MAKER INFORMATION
        </Typography>
      </Grid>
      <Stack spacing={2} marginTop={2} direction={"row"}>
        <Grid item xs={8}>
          <Stack spacing={2}>
            <Stack direction={"row"} spacing={2}>
              <Grid item xs={4}>
                <Controller
                  name="middlenameCo"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Middlename" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="middlenameCo"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Middlename" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="lastnameCo"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Lastname" fullWidth />
                  )}
                />
              </Grid>
            </Stack>
            <Controller
              name="addressCo"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Address" multiline fullWidth />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={2} md={2}>
          <Stack>
            <img
              src={comakerSrc ? comakerSrc : "/assets/noimage.jpg"}
              alt="webcam"
              name="comakerImage"
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <Button
              data-value="cameraComaker"
              variant="contained"
              onClick={handleSign}
              style={{ marginBottom: "10px" }}
            >
              Capture image
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={2} md={2}>
          <Stack>
            <img
              src={comakerSrc ? comakerSrc : "/assets/noimage.jpg"}
              alt="webcam"
              name="comakerImage"
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <Button
              data-value="cameraComaker"
              variant="contained"
              onClick={handleSign}
              style={{ marginBottom: "10px" }}
            >
              Capture image
            </Button>
          </Stack>
        </Grid>
      </Stack>
    </Grid>
  );
}
