import React, { useState, useEffect } from "react";
import moment from "moment";

const LiveDateTime = () => {
  const [formattedDate, setFormattedDate] = useState(
    moment().format("dddd MMMM Do YYYY, h:mm:ss A")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFormattedDate(moment().format("dddd MMMM Do YYYY, h:mm:ss A"));
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return <div>{formattedDate}</div>;
};

export default LiveDateTime;
