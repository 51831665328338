// Layout.js
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Navbar from "../features/dashboard/NavList";
import Sidenav from "../features/dashboard/SideNavBar";
import socketService from "../app/api/ws/initializeWebSocketService";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../app/api/auth/authSlice";

const baseUrl = process.env.REACT_APP_WS_URL;

const Layout = ({ children }) => {
  const user = useSelector(selectCurrentUser);
  //if (user) {
  useEffect(() => {
    if (user) {
      setInterval(() => {
        socketService.connect(baseUrl);
      }, 5000); // 5 minutes in milliseconds
    }
  }, [user]);
  //}

  return (
    <>
      <Navbar />
      <Box height={60} />
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
