import { Button, Stack } from "@mui/material";
import React from "react";
const BorrowerSignature = ({ signSrcBorrower, handleSign }) => {
  return (
    <>
      <Stack direction={"column"} spacing={2} style={{ width: "100%" }}>
        <Stack textAlign={"center"}>
          {signSrcBorrower ? (
            <img
              src={signSrcBorrower}
              style={{ width: "100%", height: "100px" }}
              alt="borrower signature"
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "#FFFFF",
              }}
            ></div>
          )}
          <div style={{ borderBottom: "1px solid", width: "100%" }}></div>
          Borrower Signature
        </Stack>
        <Button data-value="borrower" variant="contained" onClick={handleSign}>
          Sign
        </Button>
      </Stack>
    </>
  );
};

export default BorrowerSignature;
