import React from "react";
import { usePaymentReportQuery } from "../../app/api/reports/reportApiSlice";
import { Paper } from "@mui/material";
import { CSVLink } from "react-csv";
import ReportTable from "./reportsTable/ReportTable";
import dayjs from "dayjs";

function ccyFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function createRow(
  id,
  date,
  name,
  pamount,
  iamount,
  paid,
  interest,
  remaining,
  status,
  withdrawn
) {
  return {
    id,
    date,
    name,
    pamount,
    iamount,
    paid,
    interest,
    remaining,
    status,
    withdrawn,
  };
}

function totalIamount(items) {
  return items.map(({ iamount }) => iamount).reduce((sum, i) => sum + i, 0);
}

function totalRemaining(items) {
  return items.map(({ remaining }) => remaining).reduce((sum, i) => sum + i, 0);
}

function totalWithdrawn(items) {
  return items.map(({ withdrawn }) => withdrawn).reduce((sum, i) => sum + i, 0);
}

function totalPaid(items) {
  return items.map(({ paid }) => paid).reduce((sum, i) => sum + i, 0);
}

function PaymentReport({ dateRange, triggerReport }) {
  const { data, error, isLoading } = usePaymentReportQuery(dateRange, {
    skip: !triggerReport,
  });

  let interestAmountTotal = 0;
  let paidTotal = 0;
  let withdrawnTotal = 0;
  let remainingTotal = 0;
  let interest_amount = 0;
  let rows = [];
  let rowCSV = [];
  if (!isLoading && data) {
    data.forEach((element) => {
      interest_amount =
        (element.loan_info.loan_amount * element.loan_info.loan_interest) / 100;
      let final_amount = interest_amount + element.loan_info.loan_amount;
      rows.push(
        createRow(
          element.id,
          element.payment_date,
          element.user_info.firstname + " " + element.user_info.lastname,
          element.loan_info.loan_amount,
          element.isWithdrawn === 0 ? interest_amount : 0,
          element.isWithdrawn === 0 ? element.amount : 0,
          element.loan_info.loan_interest,
          element.remaining_balance,
          element.isWithdrawn,
          element.isWithdrawn === 1 ? element.amount : 0
        )
      );

      rowCSV.push({
        payment_date: dayjs(element.payment_date).format("MMM D, YYYY"),
        borrower_name:
          element.user_info.firstname + " " + element.user_info.lastname,
        loan_amount: ccyFormat(element.loan_info.loan_amount),
        interest_amount: ccyFormat(interest_amount),
        paid_amount: ccyFormat(element.amount),
        loan_interest: element.loan_info.loan_interest,
        remaining_balance: ccyFormat(element.remaining_balance),
      });
    });
  }
  interestAmountTotal = totalIamount(rows);
  paidTotal = totalPaid(rows);
  withdrawnTotal = totalWithdrawn(rows);
  rowCSV.push({
    payment_date: "Total:",
    borrower_name: "",
    loan_amount: "",
    interest_amount: interestAmountTotal,
    paid_amount: paidTotal,
    loan_interest: "",
    remaining_balance: "",
  });

  let content = "";

  if (triggerReport) {
    content = (
      <>
        <Paper
          elevation={3}
          style={{ padding: "20px", marginBottom: "10px", textAlign: "right" }}
        >
          <CSVLink
            data={rowCSV}
            filename={"payment_report.csv"}
            style={{
              color: "rgb(0 60 253 / 87%)",
              textDecoration: "none",
            }}
          >
            Download CSV
          </CSVLink>
        </Paper>
        <ReportTable
          rows={rows}
          isLoading={isLoading}
          interestAmountTotal={interestAmountTotal}
          totalPaid={paidTotal}
          remainingTotal={remainingTotal}
          withdrawnTotal={withdrawnTotal}
        />
      </>
    );
  } else {
    content = <div>Click on the button to generate report</div>;
  }
  return isLoading ? <div>Loading...</div> : content;
}

export default PaymentReport;
