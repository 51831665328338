import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import "../Member.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";

const memberGroup = [{ title: "4PS" }, { title: "AFP" }, { title: "Others" }];

const CivilStatusContent = ({ control, watch, type }) => (
  <Grid item xs={12} md={12}>
    <Grid item xs={12}>
      <Typography variant="h6" fontWeight="600" color={"#0A6847"}>
        Name of Spouse:
      </Typography>
    </Grid>
    {type === "reloan" || type === "view" ? (
      <Typography
        variant="span"
        sx={{ fontSize: 18 }}
        textTransform={"capitalize"}
      >
        Name:{" "}
        <Typography variant="span" fontWeight={700}>
          {watch("firstNameSpouse") +
            " " +
            watch("middleNameSpouse") +
            " " +
            watch("lastNameSpouse")}
        </Typography>
      </Typography>
    ) : (
      <Stack direction={"row"} spacing={2} marginTop={2}>
        <Grid item xs={4}>
          <Controller
            name="firstNameSpouse"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField {...field} label="First Name" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="middleNameSpouse"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Middle Name" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="lastNameSpouse"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Last Name" fullWidth />
            )}
          />
        </Grid>
      </Stack>
    )}
  </Grid>
);
const PersonalContent = ({ control, id }) => (
  <Stack direction={"row"} spacing={2}>
    <Controller
      name="rank"
      defaultValue=""
      control={control}
      render={({ field }) => (
        <TextField {...field} label="Rank" type="text" fullWidth />
      )}
    />
    <Controller
      name="serial_number"
      defaultValue=""
      control={control}
      render={({ field }) => (
        <TextField {...field} label="Serial Number" type="text" fullWidth />
      )}
    />

    <Controller
      name="unit_assign"
      defaultValue=""
      control={control}
      render={({ field }) => (
        <TextField {...field} label="Unit Assignment" type="text" fullWidth />
      )}
    />
  </Stack>
);

const PersonalInfoContent = ({
  control,
  value,
  handleBorrowerChange,
  setApplyDate,
  applyDate,
  borrowerValue,
  setMemberGroupValue,
  handleMemberGrpChange,
  memberGroupValue,
  civilStatusValue,
  imgSrc,
  handleSign,
  handleGenderChange,
  genderValue,
  handlecivilStatsChange,
  watch,
  type,
}) => {
  return (
    <>
      <Grid item xs={8} md={8}>
        <Grid item xs={12} className="gridLabelTitle">
          <Typography variant="h6" fontWeight="bold">
            PERSONAL INFORMATION
          </Typography>
        </Grid>
        <Stack spacing={2} marginTop={2}>
          <Grid item xs={12}>
            {type === "reloan" || type === "view" ? (
              <Typography
                variant="span"
                sx={{ fontSize: 18 }}
                textTransform={"capitalize"}
              >
                Date Applied:{" "}
                <Typography variant="span" fontWeight={700}>
                  {dayjs(watch("applyDate")).format("MM/DD/YYYY")}
                </Typography>
              </Typography>
            ) : (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: "100%" }} className="datePickerBox">
                  <DatePicker
                    value={applyDate}
                    label="Date applied"
                    style={{ width: "100% !important;" }}
                    onChange={(newValue) => setApplyDate(newValue)}
                    required
                  />
                </Box>
              </LocalizationProvider>
            )}
          </Grid>
          <Grid item xs={12}>
            {type === "reloan" || type === "view" ? (
              <Typography
                variant="span"
                sx={{ fontSize: 18 }}
                textTransform={"capitalize"}
              >
                Borrower Type:{" "}
                <Typography variant="span" fontWeight={700}>
                  {borrowerValue === "0" ? "With Co-maker" : "Personal Loan"}
                </Typography>
              </Typography>
            ) : (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleBorrowerChange}
                  value={borrowerValue}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="With Co-maker"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Personal Loan"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12}>
            {type === "reloan" || type === "view" ? (
              <Typography
                variant="span"
                sx={{ fontSize: 18 }}
                textTransform={"capitalize"}
              >
                Name:{" "}
                <Typography variant="span" fontWeight={700}>
                  {watch("firstName") +
                    " " +
                    watch("middleName") +
                    " " +
                    watch("lastName")}
                </Typography>
              </Typography>
            ) : (
              <Stack direction={"row"} spacing={2}>
                <Grid item xs={4}>
                  <Controller
                    name="firstName"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="First Name"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="middleName"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label="Middle Name" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="lastName"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Last Name"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
              </Stack>
            )}
          </Grid>
          <Grid item xs={12}>
            {type === "reloan" || type === "view" ? (
              <Typography
                variant="span"
                sx={{ fontSize: 18 }}
                textTransform={"capitalize"}
              >
                Member Group:{" "}
                <Typography variant="span" fontWeight={700}>
                  {memberGroupValue}
                </Typography>
              </Typography>
            ) : (
              <Autocomplete
                id="memberGroup"
                freeSolo
                options={memberGroup.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField {...params} label="Member Group" required />
                )}
                onChange={(event, newValue) => {
                  // Ensure newValue is always a string or undefined
                  const newMemberGroupValue = newValue || ""; // If newValue is null, set to empty string
                  setMemberGroupValue(newMemberGroupValue);
                  handleMemberGrpChange(event, newMemberGroupValue); // Call handleMemberGrpChange with the new value
                }}
                value={memberGroupValue}
                inputValue={memberGroupValue}
                onInputChange={(event, newInputValue) => {
                  // Ensure newInputValue is always a string or undefined
                  const newMemberGroupValue = newInputValue || ""; // If newInputValue is null, set to empty string
                  setMemberGroupValue(newMemberGroupValue);
                }}
              />
            )}
          </Grid>
          {type === "reloan" || type === "view" ? (
            <Typography
              variant="span"
              sx={{ fontSize: 18 }}
              textTransform={"capitalize"}
            >
              Address:{" "}
              <Typography variant="span" fontWeight={700}>
                {watch("address")}
              </Typography>
            </Typography>
          ) : (
            <Controller
              name="address"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Address"
                  multiline
                  fullWidth
                  required
                />
              )}
            />
          )}
          {parseInt(borrowerValue) === 1 ? (
            <PersonalContent control={control} type={type} watch={watch} />
          ) : (
            ""
          )}

          {parseInt(civilStatusValue) === 1 ? (
            <CivilStatusContent control={control} watch={watch} type={type} />
          ) : (
            ""
          )}

          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="600" color={"#0A6847"}>
              Contact Information:
            </Typography>
          </Grid>
          {type === "reloan" || type === "view" ? (
            <Stack spacing={2} direction={"row"}>
              <Grid item xs={4}>
                <Typography
                  variant="span"
                  sx={{ fontSize: 18 }}
                  textTransform={"capitalize"}
                >
                  Phone Number:{" "}
                  <Typography variant="span" fontWeight={700}>
                    {watch("phoneNumber")}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="span"
                  sx={{ fontSize: 18 }}
                  textTransform={"capitalize"}
                >
                  Telephone Number:{" "}
                  <Typography variant="span" fontWeight={700}>
                    {watch("telePhoneNumber")}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="span"
                  sx={{ fontSize: 18 }}
                  textTransform={"capitalize"}
                >
                  Email Address:{" "}
                  <Typography variant="span" fontWeight={700}>
                    {watch("emailAddress")}
                  </Typography>
                </Typography>
              </Grid>
            </Stack>
          ) : (
            <Stack direction={"row"} spacing={2}>
              <Grid item xs={4}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Phone Number"
                      type="text"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="telePhoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Telephone Number"
                      type="text"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="emailAddress"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email Address"
                      type="text"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Stack>
          )}
        </Stack>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <Typography variant="h6" fontWeight="600" color={"#0A6847"}>
            Card Information:
          </Typography>
        </Grid>
        <Stack spacing={2} direction={"row"} marginTop={2}>
          <Grid item xs={4}>
            {type === "reloan" || type === "view" ? (
              <Typography
                variant="span"
                sx={{ fontSize: 18 }}
                textTransform={"capitalize"}
              >
                Card Number:{" "}
                <Typography variant="span" fontWeight={700}>
                  {watch("cardNumber")}
                </Typography>
              </Typography>
            ) : (
              <Controller
                name="cardNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Card Number"
                    type="text"
                    fullWidth
                    required
                    inputProps={{
                      maxLength: 19, // Limit the length (16 numbers + 3 spaces)
                    }}
                    placeholder="1234 5678 9012 3456"
                    onInput={(e) => {
                      // Use a regular expression to only allow numbers and spaces
                      e.target.value = e.target.value
                        .replace(/\D/g, "")
                        // Insert spaces every 4 digits
                        .replace(/(\d{4})(?=\d)/g, "$1 ");
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {type === "reloan" || type === "view" ? (
              <Typography
                variant="span"
                sx={{ fontSize: 18 }}
                textTransform={"capitalize"}
              >
                CVC Number:{" "}
                <Typography variant="span" fontWeight={700}>
                  {watch("cardCVC")}
                </Typography>
              </Typography>
            ) : (
              <Controller
                name="cardCVC"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="CVC Number"
                    type="text"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {type === "reloan" || type === "view" ? (
              <Typography
                variant="span"
                sx={{ fontSize: 18 }}
                textTransform={"capitalize"}
              >
                Pin:{" "}
                <Typography variant="span" fontWeight={700}>
                  {watch("cardPin")}
                </Typography>
              </Typography>
            ) : (
              <Controller
                name="cardPin"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Pin Number"
                    type="password"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack>
          <img
            src={imgSrc ? imgSrc : "/assets/noimage.jpg"}
            alt="webcam"
            name="userImage"
            style={{ width: "100%", marginBottom: "10px" }}
          />
          {type === "reloan" || type === "view" ? null : (
            <Button
              data-value="camera"
              variant="contained"
              onClick={handleSign}
              style={{ marginBottom: "10px" }}
            >
              Capture image
            </Button>
          )}
        </Stack>
        <Stack spacing={2} marginTop={2}>
          {type === "reloan" || type === "view" ? (
            <Typography
              variant="span"
              sx={{ fontSize: 18 }}
              textTransform={"capitalize"}
            >
              Gender:{" "}
              <Typography variant="span" fontWeight={700}>
                {genderValue === "0" ? "Female" : "Male"}
              </Typography>
            </Typography>
          ) : (
            <FormControl>
              <FormLabel id="gender">Gender</FormLabel>
              <RadioGroup
                row
                aria-labelledby="gender"
                name="row-gender"
                onChange={handleGenderChange}
                value={genderValue}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel value="1" control={<Radio />} label="Male" />
              </RadioGroup>
            </FormControl>
          )}

          {type === "reloan" || type === "view" ? (
            <Typography
              variant="span"
              sx={{ fontSize: 18 }}
              textTransform={"capitalize"}
            >
              Civil Status:{" "}
              <Typography variant="span" fontWeight={700}>
                {civilStatusValue === "0" ? "Single" : "Married"}
              </Typography>
            </Typography>
          ) : (
            <FormControl>
              <FormLabel id="civil-status">Civil Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="civil-status"
                name="row-civil-status"
                onChange={handlecivilStatsChange}
                value={civilStatusValue}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Single"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Married"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default PersonalInfoContent;
