import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import "./print.css";
import dayjs from "dayjs";
import "./landscape.css";

function decimalFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
const baseUrl = process.env.REACT_APP_BACKEND_URL;

function LoanHistory(data) {
  const { prevLoanData, includeAttachment } = data;
  const [loanData, setLoanData] = React.useState([]);
  const [loanAttachment, setLoanAttachment] = React.useState([]);
  useEffect(() => {
    if (prevLoanData) {
      let loanData = [];
      let attachments = [];
      prevLoanData.forEach((loan, index) => {
        let classTitle = "";
        if (loan.payment.length > 3) {
          classTitle =
            parseInt(index) > 0
              ? loan.payment.length > 3
                ? "page-break"
                : ""
              : "";
        }
        {
          loan.attachments &&
            attachments.push(
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  key={index}
                  mb={2}
                  style={{ paddingTop: "0px" }}
                  mt={2}
                  className={`page-break ${
                    includeAttachment ? "" : "exclude-from-print dont-show"
                  }`}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                      <h3>Loan Attachment</h3>
                    </Grid>
                    <Grid item xs={6} md={6} sx={{ textAlign: "center" }}>
                      <Avatar
                        alt="Remy Sharp"
                        src={`${baseUrl}/uploads/${loan.attachments.acknowledgement}`}
                        sx={{ width: "100%", height: "100%" }}
                        variant="square"
                      />
                    </Grid>
                    <Grid item xs={6} md={6} sx={{ textAlign: "center" }}>
                      <Avatar
                        alt="Remy Sharp"
                        src={`${baseUrl}/uploads/${loan.attachments.certification_doc}`}
                        sx={{ width: "100%", height: "100%" }}
                        variant="square"
                      />
                    </Grid>
                    <Grid item xs={6} md={6} sx={{ textAlign: "center" }}>
                      <Avatar
                        alt="Remy Sharp"
                        src={`${baseUrl}/uploads/${loan.attachments.promisory_note}`}
                        sx={{ width: "100%", height: "100%" }}
                        variant="square"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
        }
        loanData.push(
          <>
            <Grid
              item
              xs={12}
              md={12}
              key={index}
              mb={2}
              style={{ paddingTop: "0px" }}
              className={classTitle}
              mt={2}
            >
              <Stack
                direction={"row"}
                spacing={0}
                sx={{ borderBottom: "2px solid" }}
              >
                <Stack direction="column" style={{ width: "50%" }} spacing={0}>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Name:</strong>
                    </Typography>
                    <Typography>{data.name}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Capital Amount:</strong>
                    </Typography>
                    <Typography>₱{decimalFormat(loan.amount)}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Loan Type:</strong>
                    </Typography>
                    <Typography>
                      {loan.loan_type === 1 ? "Flexible" : "Monthly"}
                    </Typography>
                  </Stack>
                </Stack>
                {loan.loan_type === 0 ? (
                  <Stack
                    direction="column"
                    style={{ width: "50%" }}
                    spacing={0}
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ width: "200px" }}>
                        <strong>Monthy Payment:</strong>
                      </Typography>
                      <Typography>
                        ₱{decimalFormat(loan.monthly_payment)}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ width: "200px" }}>
                        <strong>Loan Term:</strong>
                      </Typography>
                      <Typography>{`${loan.term} Months`}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ width: "200px" }}>
                        <strong>Total Loan Amount:</strong>
                      </Typography>
                      <Typography>{`₱${decimalFormat(
                        parseFloat(loan.monthly_payment) * loan.term
                      )}`}</Typography>
                    </Stack>
                  </Stack>
                ) : (
                  ""
                )}
              </Stack>
              <Grid container spacing={2} mt={2}>
                {processPaymentData(
                  loan.payment,
                  loan.loan_type,
                  loan.monthly_payment,
                  loan.term
                )}
              </Grid>
            </Grid>
          </>
        );
      });
      setLoanData(loanData);
    }
  }, [prevLoanData, includeAttachment]);

  const processPaymentData = (prevLoanData, type, monthlyPayment, term) => {
    let content = [];
    if (prevLoanData.length > 0) {
      let countPayment = 1;
      let count = 1;
      let monthly_paid_terms = 0;
      let monthly_paid_amount = 0;
      let monthly_last_paid_amount = 0;
      let monthly_payment_change = 0;
      let monthly_withdrawAmount = 0;
      let remaingBalanceData = [];
      prevLoanData.forEach((payment, index) => {
        if (parseInt(count) > 4) {
          count = 1;
          content.push(
            <Grid item xs={12} md={12} className="page-break"></Grid>
          );
        }

        let total_paid_amount = 0;
        let last_paid_amount = 0;
        let payment_change = 0;
        let withdrawAmount = 0;
        let amount = 0;
        let loan_interest = 0;
        let remainingBalance = 0;
        let total_loan_amount = 0;
        let loan_interest_amount = 0;
        let amount_due = 0;
        let loan_amount = 0;
        let outstanding_balance = 0;

        if (payment.status === 1) {
          withdrawAmount = parseFloat(payment.amount);
          monthly_withdrawAmount += parseFloat(payment.amount);
        } else {
          monthly_paid_terms += parseFloat(payment.num_term);
          monthly_paid_amount += parseFloat(payment.amount);
          total_paid_amount += parseFloat(payment.amount);
        }

        if (payment.apply_to_interest === 1) {
          payment_change += parseFloat(payment.amount);
          monthly_payment_change += parseFloat(payment.amount);
        } else {
          payment_change += parseFloat(payment.payment_change);
          monthly_payment_change += parseFloat(payment.payment_change);
        }

        if (index === payment.length - 1) {
          last_paid_amount = parseFloat(payment.amount);
          monthly_last_paid_amount = parseFloat(payment.amount);
        }
        //amount =
        //  parseFloat(payment.loan_info.loan_amount) +
        //  parseFloat(withdrawAmount) -
        //  payment_change;
        //loan_interest = parseFloat(payment.loan_info.loan_interest);
        if (type === 1) {
          if (payment_change > -1) {
            total_loan_amount =
              parseFloat(payment.loan_info.loan_amount) *
                (parseFloat(payment.loan_info.loan_interest) / 100) *
                payment.loan_info.loan_term +
              parseFloat(payment.loan_info.loan_amount);
            if (payment.status === 1) {
              remainingBalance = payment.loan_info.loan_amount + withdrawAmount;
            } else {
              remainingBalance = total_loan_amount - total_paid_amount;
            }
          } else {
            total_loan_amount =
              parseFloat(payment.loan_info.loan_amount) *
                (parseFloat(payment.loan_info.loan_interest) / 100) *
                payment.loan_info.loan_term +
              parseFloat(payment.loan_info.loan_amount);

            outstanding_balance = true;

            remainingBalance = payment.loan_info.loan_amount;
          }
          loan_interest_amount =
            parseFloat(payment.loan_info.loan_amount) *
            (parseFloat(payment.loan_info.loan_interest) / 100);
          amount_due = loan_interest_amount;
          withdrawAmount = payment.loan_info.loan_amount + withdrawAmount;
          loan_amount = payment.loan_info.loan_amount;
        } else {
          amount_due = parseFloat(monthlyPayment);
          total_loan_amount =
            (parseFloat(payment.loan_info.loan_amount) / 100) *
              parseFloat(payment.loan_info.loan_interest) *
              term +
            parseFloat(payment.loan_info.loan_amount);

          /*total_loan_amount =
              parseFloat(payment.loan_info.loan_amount) *
                (parseFloat(payment.loan_info.loan_interest) / 100) *
                payment.loan_info.loan_term +
              parseFloat(payment.loan_info.loan_amount);*/
          remainingBalance =
            total_loan_amount - (monthly_paid_amount - monthly_withdrawAmount);
          if (index === 0) {
            remaingBalanceData.push(total_loan_amount);
            remaingBalanceData.push(remainingBalance);
          } else {
            remaingBalanceData.push(remainingBalance);
          }

          loan_amount = remaingBalanceData[index];
        }
        count++;
        if (payment.status === 0) {
          countPayment++;
          content.push(
            <Grid item xs={6} md={6}>
              <Card
                className="box-shadow-none"
                sx={{
                  height: "100%",
                  minHeight: "200px",
                  border: "1px solid",
                }}
              >
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ width: "120px" }}>
                      <strong>Payment Date:</strong>
                    </Typography>
                    <Typography>
                      {dayjs(payment.payment_date).format("MMM D,YYYY")}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Loan Interest:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "right",
                        width: "150px",
                      }}
                    >
                      {payment.loan_info.loan_interest}%
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Loan Amount:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "right",
                        width: "150px",
                      }}
                    >
                      ₱{decimalFormat(parseFloat(loan_amount))}
                    </Typography>
                  </Stack>
                  {payment.status === 1 ? (
                    <Stack direction="row" spacing={2}>
                      <Typography sx={{ width: "200px" }}>
                        <strong>Total Loan Amount:</strong>
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "right",
                          width: "100px",
                        }}
                      >
                        ₱{decimalFormat(total_loan_amount)}
                      </Typography>
                    </Stack>
                  ) : (
                    ""
                  )}

                  <Stack direction="row" spacing={2} sx={{ marginTop: "40px" }}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Actual Payment:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "right",
                        width: "150px",
                        color: outstanding_balance ? "red" : "",
                      }}
                    >
                      ₱{decimalFormat(payment.amount)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Amount Due:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        borderBottom: "1px solid",
                        textAlign: "right",
                        width: "150px",
                      }}
                    >
                      ₱{decimalFormat(amount_due)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Change:</strong>
                    </Typography>
                    <Typography sx={{ textAlign: "right", width: "150px" }}>
                      ₱{decimalFormat(payment.amount - amount_due)}
                    </Typography>
                  </Stack>
                  {payment.apply_to_interest === 1 && (
                    <Stack direction="row" spacing={2} mt={2}>
                      <Typography>
                        <strong>Note:</strong>
                      </Typography>
                      <Typography>Apply payment to loan amount</Typography>
                    </Stack>
                  )}
                  {outstanding_balance === true && (
                    <Stack direction="row" spacing={2} mt={2}>
                      <Typography sx={{ width: "200px" }}>
                        <strong>Outstanding Balance:</strong>
                      </Typography>
                      <Typography sx={{ textAlign: "right", width: "100px" }}>
                        ₱{decimalFormat(payment_change)}
                      </Typography>
                    </Stack>
                  )}
                  <Stack direction="row" spacing={2} sx={{ marginTop: "40px" }}>
                    <Typography sx={{ width: "200px" }}>
                      <strong>Remaining Balance:</strong>
                    </Typography>
                    <Typography sx={{ textAlign: "right", width: "100px" }}>
                      ₱{decimalFormat(remainingBalance)}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        } else {
          //remainingBalance = remainingBalance + withdraw_amount;
          content.push(
            <Grid item xs={6} md={6}>
              <Card
                className="box-shadow-none"
                sx={{
                  height: "100%",
                  minHeight: "300px",
                  border: "1px solid",
                }}
              >
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Withdraw Date:</strong>
                    </Typography>
                    <Typography>
                      {dayjs(payment.payment_date).format("MMM D,YYYY")}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Loan Amount:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "right",
                        width: "150px",
                      }}
                    >
                      ₱{decimalFormat(parseFloat(loan_amount))}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ width: "150px" }}>
                      <strong>Withdraw Amount:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        borderBottom: "1px solid",
                        textAlign: "right",
                        width: "150px",
                      }}
                    >
                      ₱{decimalFormat(payment.amount)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ width: "200px" }}>
                      <strong>Remaining Balance:</strong>
                    </Typography>
                    <Typography sx={{ textAlign: "right", width: "100px" }}>
                      ₱{decimalFormat(remainingBalance)}
                    </Typography>
                  </Stack>
                  {payment.withdraw_info && (
                    <>
                      <Stack direction="row" spacing={2} mb={2} mt={2}>
                        <Typography sx={{ width: "150px" }}>Notes</Typography>
                        <Typography>{payment.notes}</Typography>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Avatar
                          alt="Remy Sharp"
                          src={payment.withdraw_info.image_url}
                          sx={{ width: "100px", height: "100px" }}
                          variant="square"
                        />
                        <Avatar
                          alt="Remy Sharp"
                          src={payment.withdraw_info.signature}
                          sx={{ width: "70px", height: "70px" }}
                          variant="square"
                        />
                        <Avatar
                          alt="Remy Sharp"
                          src={`${baseUrl}/uploads/${payment.withdraw_info.attachment}`}
                          sx={{ width: "100px", height: "100px" }}
                          variant="square"
                        />
                      </Stack>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          );
        }
      });
    }
    return content;
  };

  return (
    <>
      <Box
        className={`page-break ${
          data.loanHistory ? "" : "exclude-from-print dont-show"
        }`}
      >
        <Paper
          elevation={3}
          sx={{ padding: "30px", marginBottom: "20px" }}
          className="comakerInfo"
        >
          <Grid container spacing={2} sx={{ padding: "40px" }}>
            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <h3>Current Loan Transaction</h3>
            </Grid>
            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <Typography
                variant="div"
                sx={{ fontSize: "14px", fontWeight: 700, float: "right" }}
              >
                Date: {dayjs().format("MMMM D, YYYY")}
              </Typography>
            </Grid>
            {loanData}
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

export default LoanHistory;
