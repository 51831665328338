const computeFlexible = (data) => {
  let today;
  let total_paid_terms = 0; // Initialize the sum
  let total_paid_amount = 0;
  let last_paid_amount = 0;
  let payment_change = 0;
  let remaining_balance = 0;
  let amount = 0;
  let loan_interest = 0;
  let term = 0;
  let loanAmount = 0;
  let loanTerm = 0;
  let finalAmount = 0;
  let monthlyPayment = 0;
  let withdrawAmount = 0;
  let originalAmount = 0;

  if (Array.isArray(data.payment)) {
    data.payment.forEach((payment, index) => {
      if (payment.payment_change > -1) {
        if (payment.status === 1) {
          withdrawAmount += parseFloat(payment.amount);
        } else {
          total_paid_terms += parseFloat(payment.num_term);
          total_paid_amount += parseFloat(payment.amount);
        }

        if (payment.apply_to_interest === 1) {
          payment_change += parseFloat(payment.amount);
        } else {
          payment_change += parseFloat(payment.payment_change);
        }

        if (index === data.payment.length - 1) {
          last_paid_amount = parseFloat(payment.amount);
        }
      }
    });
  } else {
    // Handle the case where data.payment is not an array or is undefined
    console.error("data.payment is not defined or not an array.");
    // Optionally, initialize your variables to avoid reference errors
    total_paid_terms = 0;
    total_paid_amount = 0;
    last_paid_amount = 0;
    payment_change = 0;
  }

  originalAmount = parseFloat(data.amount);

  amount =
    parseFloat(data.amount) + parseFloat(withdrawAmount) - payment_change;

  loan_interest = parseFloat(data.interest);
  term = parseFloat(data.term);
  loanAmount = (amount / 100) * loan_interest;
  loanTerm = loanAmount * term;
  finalAmount = amount + loanTerm;
  monthlyPayment = finalAmount / term;

  remaining_balance = finalAmount;
  if (data.loan_type === 1) {
    monthlyPayment = finalAmount - amount;
  }

  if (
    data.effectived_date === null ||
    typeof data.effectived_date === "undefined"
  ) {
    today = new Date();
  } else {
    today = new Date(data.effectived_date);
  }

  let next_due_date = "";
  if (total_paid_terms === 0) {
    const date = new Date(today);
    date.setMonth(today.getMonth());
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    next_due_date = `${month} ${day},  ${year}`;
  } else {
    const date = new Date(today);
    date.setMonth(today.getMonth() + total_paid_terms + 2);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    next_due_date = `${month} ${day},  ${year}`;
  }

  return {
    effectived_date: data.effectived_date,
    loanId: data.loanId,
    paidTerms: total_paid_terms,
    paidAmount: total_paid_amount,
    loanAmount: amount,
    loanTerm: term,
    loanInterest: loan_interest,
    finalAmount: finalAmount,
    due_date: next_due_date,
    monthlyPayment: monthlyPayment,
    payments: data.payment,
    loanType: data.loan_type,
    lastPaidAmount: last_paid_amount,
    loan_status: data.loan_status,
    remaining_balance: remaining_balance,
    originalAmount: originalAmount,
  };
};

export default computeFlexible;
