import React, { useCallback, useEffect } from "react";
import {
  Box,
  Backdrop,
  CircularProgress,
  Paper,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tables from "../../components/tables/Table.js";
import DottedButton from "../../components/buttons/DottedButtons.js";
import LiveDateTime from "../../components/widgets/LiveDateTime.js";
import { useNavigate } from "react-router-dom";
import { useDashboardData } from "../../hooks/useDashboardData.js";
import "./dashboard.css";
import ResponsiveDialog from "../../components/modals/Dialog.js";
import SharedDialog from "../../components/modals/SharedDialog.js";
import dayjs from "dayjs";
import { useApproveLoanMutation } from "../../app/api/loans/loanApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "../../app/api/auth/authSlice.js";
import socketService from "../../app/api/ws/initializeWebSocketService.js";
import useAuth from "../../hooks/useAuth.js";
import ResponsiveList from "../../components/list/ResponsiveList.js";
import { CustomSnackbar } from "../../components/popover/Snackbar.js";
import { MemberModal } from "../../components/modals/MemberModals.js";

function decimalFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
export default function Dashboard() {
  const { isLoading, paymentData, loanInfoData, isLoanSuccess, loanData } =
    useDashboardData("");
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
  const [effectiveDate, setEffectiveDate] = React.useState("");
  const [dialogMsg, setDialogMsg] = React.useState("");
  const [dialogChoice, setDialogChoice] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [loanId, setLoanId] = React.useState("");
  const [approveLoan] = useApproveLoanMutation();
  const user_id = useSelector(selectCurrentUserId);
  const { status: userStatus } = useAuth();
  const currentDate = dayjs().format("MMM DD, YYYY");
  const [subAppData, setSubAppData] = React.useState([]);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [profileName, setProfileName] = React.useState(null);
  const [modalTitle, setmodalTitle] = React.useState(null);
  const [modalType, setmodalType] = React.useState(null);
  const [reqDataValue, setmodalValue] = React.useState(null);
  const [bodyContent, setBodyContent] = React.useState(null);

  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [snackBarColor, setSnackBarColor] = React.useState("");

  const handleCloseSnackbar = () => {
    setPopoverOpen(false);
  };
  const handleDialogClose = () => {
    setIsDialogOpen2(false);
  };

  const handleDialogYes = useCallback(async () => {
    try {
      const statusData = {
        status: status === 1 ? 2 : 1,
        added_by: user_id,
        loan_id: loanId,
        effectiveDate: effectiveDate,
      };
      const result = await approveLoan(statusData).unwrap();
      if (result) {
        socketService.sendMessage({ type: "refresh" });
        if (status === 1) {
          setSnackBarMsg("Loan successfully approved");
          setSnackBarColor("success");
          setPopoverOpen(true);
        } else {
          setSnackBarMsg("Loan successfully rejected");
          setSnackBarColor("error");
          setPopoverOpen(true);
        }
        setIsDialogOpen2(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [status, user_id, loanId, effectiveDate, approveLoan]);

  useEffect(() => {
    if (dialogChoice === 1) {
      if (status === 0) {
        handleDialogYes();
      } else {
        setIsDialogOpen2(true);
      }
      setDialogChoice("");
    }
  }, [dialogChoice, status, handleDialogYes]);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/members/add");
  };
  //Dashboard table data
  let options = {};
  options = {
    search: true,
    download: false,
    viewColumns: false,
    filter: true,
    filterType: "dropdown",
    jumpToPage: true,
    responsive: "standard", // Options: "standard", "vertical", "simple"
    print: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    setRowProps: (row, dataIndex) => {
      if (dataIndex === 0) {
        return {
          className: "MuiDataTable-FirstRow",
          style: { display: "none !important" },
        };
      }
      return {};
    },
    customHeadRender: () => {
      return <th style={{ display: "none !important" }} />;
    },
  };

  const title = "";
  const btnOptions = ["View"];
  const customBodyRenderFunction = (dataIndex, rowData) => {
    const data = rowData[dataIndex]; // Assuming rowData[dataIndex] gives the required row data
    if (data) {
      let updatedBtnOptions = [...btnOptions];

      if (data.status === "Pending") {
        if (userStatus === "Admin") {
          updatedBtnOptions.push("Edit Loan", "Approve", "Reject");
        } else {
          updatedBtnOptions.push("Edit Loan");
        }
      } else if (data.status === "Approved") {
        if (data.remaining_balance !== "₱0.00") {
          updatedBtnOptions.push("Less Payment", "Reloan");
        }

        //if (data.loan_type === "Flexible") {
        updatedBtnOptions.push("Withdraw");
        //}
      }
      return (
        <DottedButton
          options={updatedBtnOptions}
          data={loanInfoData[data.id]}
          indexValue={dataIndex}
          borrower_id={data.borrower_id} // Adding borrower_id here
          uid={data.borrower_id}
          id={data.borrower_id}
          loanId={data.id}
          loanStatus={data.loan_status}
          setUserId={setLoanId}
          type={"loans"}
          isDialogOpen={setIsDialogOpen}
          dialogMsg={setDialogMsg}
          status={setStatus}
          effectiveDate={dayjs(data.effective_date)}
          setEffectiveDate={setEffectiveDate}
          setPopoverOpen={setPopoverOpen}
          setModalOpen={setModalOpen}
          setProfileName={setProfileName}
          setmodalTitle={setmodalTitle}
          setmodalType={setmodalType}
          setmodalValue={setmodalValue}
          setBodyContent={setBodyContent}
        />
      );
    } else {
      return null; // Return null or any fallback if data is not available
    }
  };
  let columns = [];

  if (userStatus === "Manager") {
    columns = [
      { name: "ID", options: { display: false } },
      { name: "Borrower ID", options: { display: false } },
      { name: "effective_date", options: { display: false } },
      { name: "Name", options: { filterOptions: { fullWidth: true } } },
      { name: "Principal Amount" },
      {
        name: "Next Due Date",
        options: {
          filter: true,
          sort: true,
          //filterList: [currentDate], // Default selected value
          //customFilterListOptions: {
          //  render: (v) => `${v}`,
          //},
          // additional options
        },
      },
      "Amount to Pay",
      "Loan Type",
      "Paid Terms",
      "Remaining Balance",
      {
        name: "Status",
        options: {
          filter: true,
          sort: true,
          //filterList: ["Approved"], // Default selected value
          //customFilterListOptions: {
          //  render: (v) => `${v}`,
          //},
        },
      },
      {
        name: "",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) =>
            customBodyRenderFunction(dataIndex, tableData),
        },
      },
    ];
  } else {
    columns = [
      { name: "ID", options: { display: false } },
      { name: "Borrower ID", options: { display: false } },
      { name: "effective_date", options: { display: false } },
      { name: "Name", options: { filterOptions: { fullWidth: true } } },
      { name: "Principal Amount" },
      {
        name: "Next Due Date",
        options: {
          filter: true,
          sort: true,

          // additional options
        },
      },
      "Amount to Pay",
      "Loan Type",
      "Paid Terms",
      "Remaining Balance",
      {
        name: "Status",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) =>
            customBodyRenderFunction(dataIndex, tableData),
        },
      },
    ];
  }
  const sortColumn = {
    name: "Status",
    direction: "asc", // or 'desc'
  };
  let countToday = 0;
  const tableData = loanInfoData
    .filter((item) => item.status !== 3)
    .filter((item) => item.status !== 1)
    .map((item) => {
      if (
        dayjs(item.computed.due_date).format("DD-MM-YYYY") ===
        dayjs(currentDate).format("DD-MM-YYYY")
      ) {
        countToday++;
      }
      //if item.status is 3 don't show it

      return {
        id: item.computed.loanId,
        borrower_id: item.borrower_id, // Include borrower_id in the data
        effective_date: item.effectived_date,
        name: `${item.lastname}, ${item.firstname} ${
          item.middlename ? item.middlename.charAt(0) + "." : ""
        } `,
        loan_amount: `₱${decimalFormat(item.computed.loanAmount)}`,
        next_due_date: item.computed.due_date, // Add logic for Next Due Date if available
        amount_to_be_paid: `₱${decimalFormat(item.computed.monthlyPayment)}`,
        loan_type:
          item.loan_type === 0
            ? "Monthly"
            : item.loan_type === 1
            ? "Flexible"
            : "10th - 25th",
        loan_terms:
          item.loan_type === 0
            ? `${item.computed.paidTerms} out of ${item.loan_terms}`
            : `${item.computed.paidTerms} of ${item.loan_terms} Months`,
        remaining_balance: `₱${decimalFormat(item.computed.remaining_balance)}`,
        status:
          item.status === 0
            ? "Pending"
            : item.status === 1
            ? "Rejected"
            : item.status === 2
            ? "Approved"
            : "Fully Paid",
      };
    })
    .sort((a, b) => {
      if (a.status === "Approved" && b.status !== "Approved") {
        return -1;
      } else if (a.status !== "Approved" && b.status === "Approved") {
        return 1;
      } else if (a.status === "Pending" && b.status !== "Pending") {
        return -1;
      } else if (a.status !== "Pending" && b.status === "Pending") {
        return 1;
      } else {
        return dayjs(a.next_due_date).isBefore(dayjs(b.next_due_date)) ? -1 : 1;
      }
    });

  //end of dashboard table

  useEffect(() => {
    if (isLoanSuccess) {
      let barChartData = [];
      let pending = 0;
      let approved = 0;
      let rejected = 0;
      let closed = 0;
      Object.keys(loanData).forEach((key, index) => {
        if (key === "0") {
          pending = Object.keys(loanData[key]).length;
        }
        if (key === "1") {
          rejected = Object.keys(loanData[key]).length;
        }
        if (key === "2") {
          approved = Object.keys(loanData[key]).length;
        }
        if (key === "3") {
          closed = Object.keys(loanData[key]).length;
        }
      });
      barChartData = [
        {
          title: "Pending",
          value: pending,
        },
        {
          title: "Approved",
          value: approved,
        },
        {
          title: "Reject",
          value: rejected,
        },
        {
          title: "Closed",
          value: closed,
        },
      ];
      setSubAppData(barChartData);
    }
  }, [isLoanSuccess, loanData]);

  const categories = [
    {
      id: 1,
      title: "Expected Collection",
      value: countToday,
      bgColor: "#973131",
    },
    {
      id: 2,
      title: "Collected",
      value: paymentData.widgetCount,
      bgColor: "#FDA403",
    },
    {
      id: 3,
      title: "Payment Received",
      value: `₱${decimalFormat(parseFloat(paymentData.payment))}`,
      fsize: "30px",
      bgColor: "#87A922",
    },
    {
      id: 4,
      title: "Newly Applied",
      value: paymentData.newlyApplied,
      bgColor: "#1679AB",
    },
    {
      id: 5,
      title: "Application",
      value: 7,
      bgColor: "#32012F",
      subTitle: subAppData,
    },
  ];

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <Paper elevation={3} sx={{ p: 2, overflowX: "auto" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <h2 className="dateFormatting">
        <LiveDateTime />
      </h2>
      <Box height={20} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <ResponsiveList categories={categories} />
          </Stack>
        </Grid>
        <Box height={30} />
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={handleButtonClick}
              startIcon={<AddCircleIcon />}
            >
              New Application
            </Button>
          </Grid>
          <Box height={10} />
          <Tables
            columns={columns}
            sort={sortColumn}
            data={tableData}
            title={title}
            options={options}
          />
        </Grid>
      </Grid>
      {modalOpen && (
        <MemberModal
          closeModal={handleCloseModal}
          onSubmit={handleCloseModal}
          onCancel={handleCloseModal}
          openModal={modalOpen}
          title={modalTitle}
          type={modalType}
          reqValue={reqDataValue}
          profileName={profileName}
          BodyContent={bodyContent}
          setSnackBarMsg={setSnackBarMsg}
          setSnackBarColor={setSnackBarColor}
          setPopoverOpen={setPopoverOpen}
        />
      )}
      {isDialogOpen && (
        <SharedDialog
          isDialogOpen={setIsDialogOpen}
          isOpen={isDialogOpen}
          title={"Borrower"}
          bodyContent={dialogMsg}
          setDialogChoice={setDialogChoice}
        />
      )}
      {isDialogOpen2 && (
        <ResponsiveDialog
          dialogOpen={isDialogOpen2}
          handleDialogYes={handleDialogYes}
          handleDialogClose={handleDialogClose}
          setEffectiveDate={setEffectiveDate}
          effectiveDate={effectiveDate}
          dialogType={status}
        />
      )}
      {popoverOpen && (
        <CustomSnackbar
          openSnackBar={popoverOpen}
          closeSnackBar={handleCloseSnackbar}
          messageData={snackBarMsg}
          colorVariant={snackBarColor}
        />
      )}
    </Paper>
  );
}
