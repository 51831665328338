import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import DateRangePickerComponent from "../../components/dateRange/DateRangePicker";
import PaymentReport from "../reports/PaymentReport";
import BorrowerList from "../reports/BorrowerList";

let options = ["Borrower List", "Payment Reports", "Loan Reports"];

const ManageReports = () => {
  const [dates, setDates] = useState({ datestart: "", dateend: "" });
  const [triggerReport, setTriggerReport] = useState(false);
  const [reportType, setReportType] = useState(options[0]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: dayjs(new Date()).add(7, "day").toDate(),
      key: "selection",
    },
  ]);
  let content = "";
  const handleGenerateReport = () => {
    //const startDate = dateRange[0].format("MMMM DD, YYYY");
    //const endDate = dateRange[1].format("MMMM DD, YYYY");
    const startDate = dayjs(dateRange[0].startDate).format("YYYY-MM-DD");
    const endDate = dayjs(dateRange[0].endDate).format("YYYY-MM-DD");
    setDates({ datestart: startDate, dateend: endDate });
    setTriggerReport(true);
    // Here you can implement your report generation logic
  };

  if (reportType === "Payment Reports") {
    content = <PaymentReport dateRange={dates} triggerReport={triggerReport} />;
  } else if (reportType === "Loan Reports") {
    content = "Loan Reports";
  } else if (reportType === "Borrower List") {
    content = <BorrowerList dateRange={dates} triggerReport={triggerReport} />;
  } else {
    content = "No report type selected";
  }

  return (
    <>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <h1>Manage Reports</h1>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <Stack direction={"row"} spacing={2}>
                  <Typography
                    sx={{
                      width: "200px",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                    variant="h6"
                  >
                    Report Type
                  </Typography>
                  <Autocomplete
                    options={options}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                    value={reportType}
                    onChange={(event, newValue) => {
                      setReportType(newValue);
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePickerComponent
                  setDateRange={setDateRange}
                  dateRange={dateRange}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateReport}
              >
                Generate Report
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
          {content}
        </Paper>
      </Paper>
    </>
  );
};

export default ManageReports;
