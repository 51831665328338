import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import "./print.css";
import ComakerDataFrom from "./ComakerDataFrom";
import LoanHistory from "./LoanHistory";
import CurrentLoan from "./CurrentLoan";

function decimalFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
function UserDataForm(data) {
  const [checkedState, setCheckedState] = React.useState({
    loanHistory: false,
    comakerCertification: false,
    attachments: false,
    currentLoan: false,
  });
  const handleIncludePayment = (event) => {
    setCheckedState({
      ...checkedState,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <>
      <Paper elevation={3} style={{ marginBottom: "20px", padding: 30 }}>
        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedState.comakerCertification}
                  onChange={handleIncludePayment}
                  name="comakerCertification"
                />
              }
              sx={{ color: "red" }}
              label="Include Comaker Certification"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedState.includeLoanHistory}
                  onChange={handleIncludePayment}
                  name="loanHistory"
                />
              }
              sx={{ color: "red" }}
              label="Include Loan History"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedState.includeAttachments}
                  onChange={handleIncludePayment}
                  name="attachments"
                />
              }
              sx={{ color: "red" }}
              label="Include Attachments"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedState.includeCurrentLoan}
                  onChange={handleIncludePayment}
                  name="currentLoan"
                />
              }
              sx={{ color: "red" }}
              label="Include current loan"
            />
          </FormGroup>
        </Stack>
      </Paper>

      <Box ref={data.componentRef}>
        <Paper
          elevation={3}
          style={{ padding: 30, marginBottom: "20px" }}
          className="ProfileInfo"
        >
          <Stack spacing={0} sx={{ padding: "40px;" }}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              UPPER 28TH LENDING CORPORATION
            </Typography>
            <Typography variant="p" sx={{ textAlign: "center" }}>
              28TH IB Upper Calarian, Zamboanga City
            </Typography>
            <Typography variant="p" sx={{ textAlign: "center" }}>
              Non-VAT Reg. Tin Number: 264-782-012-000
            </Typography>
            <Typography variant="p" sx={{ textAlign: "center" }}>
              Business Permit Registration Number: 08302
            </Typography>
            <Stack spacing={0} sx={{ marginTop: "30px" }}>
              <Typography
                sx={{
                  textAlign: "right",

                  fontWeight: 700,
                }}
              >
                Date:{" "}
                <span style={{ borderBottom: "1px solid" }}>
                  {dayjs().format("MM-DD-YYYY")}
                </span>
              </Typography>
              <Typography
                sx={{ fontWeight: 700, marginBottom: "10px !important" }}
              >
                I. Personal Information
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                sx={{ alignItems: "right" }}
                spacing={2}
              >
                <Stack spacing={0} sx={{ width: "60%" }}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Typography sx={{ width: "40px" }}>Name:</Typography>
                    <Typography
                      sx={{
                        borderBottom: "1px solid",
                        width: "100%",
                        fontWeight: 700,
                        textTransform: "capitalize",
                      }}
                    >
                      {""}
                      {data.userData.name}
                    </Typography>
                  </Stack>

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={0}>
                    <Stack direction={"row"} sx={{ width: "40%" }} spacing={2}>
                      <Typography sx={{ width: "40px" }}> Rank:</Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {" "}
                        {data.userData.rank}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} sx={{ width: "60%" }} spacing={2}>
                      <Typography sx={{ width: "300px" }}>
                        Serial Number:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {data.userData.serial}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack spacing={0}>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "230px" }}>
                        Unit Assignment:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                          textTransform: "capitalize",
                        }}
                      >
                        {data.userData.unit_assign}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Typography sx={{ marginTop: "10px !important" }}>
                      Status:
                    </Typography>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <FormGroup>
                        <FormControlLabel
                          className="checkboxStatus"
                          control={
                            <Checkbox
                              defaultChecked={
                                data.userData.status === "0" ? true : false
                              }
                              disabled={true}
                            />
                          }
                          label="Single"
                        ></FormControlLabel>
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          className="checkboxStatus"
                          control={
                            <Checkbox
                              defaultChecked={
                                data.userData.status === "1" ? true : false
                              }
                              disabled={true}
                            />
                          }
                          label="Married"
                        />
                      </FormGroup>
                    </Stack>
                  </Stack>
                  <Stack spacing={0}>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "140px" }}>
                        Married to:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                          textTransform: "capitalize",
                        }}
                      ></Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "200px" }}>
                        Home Address:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                          textTransform: "capitalize",
                        }}
                      >
                        {data.userData.address}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "250px" }}>
                        Cellphone Number:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {data.userData.contact_number}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "250px" }}>
                        Telephone Number:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {data.userData.telephone_number}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "180px" }}>
                        Email Address:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {data.userData.email}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      marginTop: "30px !important",
                      marginBottom: "20px !important",
                    }}
                  >
                    II. Transaction Information
                  </Typography>
                  <Stack spacing={0}>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "160px" }}>
                        Amount Loan:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: 700,
                        }}
                      >
                        {" "}
                        ₱
                        {data.loanData[0]
                          ? decimalFormat(data.loanData[0].amount)
                          : ""}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography style={{ width: "500px" }}>
                        By{" "}
                        <span
                          style={{
                            borderBottom: "1px solid",
                            width: "50px",
                            display: "inline-block",
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: 700,
                          }}
                        >
                          {data.loanData[0] ? data.loanData[0].interest : ""}%
                        </span>{" "}
                        Interest, Monthly Amortization:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "170px",
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: 700,
                        }}
                      >
                        ₱{" "}
                        {data.loanData[0]
                          ? decimalFormat(
                              data.loanData[0].loan_type === 1
                                ? data.loanData[0].interest_amount *
                                    data.loanData[0].term
                                : data.loanData[0].monthly_payment
                            )
                          : ""}
                      </Typography>
                    </Stack>
                    <Typography>
                      Payable within:{" "}
                      <span
                        style={{
                          borderBottom: "1px solid",
                          width: "60px",
                          display: "inline-block",
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: 700,
                        }}
                      >
                        {data.loanData[0] ? data.loanData[0].term : ""}
                      </span>{" "}
                      Months
                    </Typography>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "300px" }}>
                        Payment Effective On:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid",
                          width: "100%",
                          fontWeight: 700,
                        }}
                      >
                        {data.loanData[0]
                          ? dayjs(data.loanData[0].effectived_date).format(
                              "MMM DD, YYYY"
                            )
                          : ""}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography sx={{ width: "180px" }}>
                        Loan Purpose:
                      </Typography>
                      <Typography
                        sx={{ borderBottom: "1px solid", width: "100%" }}
                      >
                        {data.loanData[0] ? data.loanData[0].loan_purpose : ""}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack spacing={0}>
                  <Stack
                    spacing={2}
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Avatar
                      src={data.userData.image}
                      sx={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      variant="square"
                    ></Avatar>
                  </Stack>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    sx={{ justifyContent: "center" }}
                    spacing={2}
                    marginTop={"100px !important"}
                  >
                    <Stack
                      spacing={0}
                      sx={{ textAlign: "center", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          minWidth: "100px",
                          minHeight: "100px",
                          width: "100%",
                          border: "1px solid",
                        }}
                      ></Box>
                      <Typography>Left Thumb Mark</Typography>
                    </Stack>
                    <Stack
                      spacing={0}
                      sx={{ textAlign: "center", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          minWidth: "100px",
                          minHeight: "100px",
                          width: "100%",
                          border: "1px solid",
                        }}
                      ></Box>
                      <Typography>Right Thumb Mark</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Box sx={{ marginTop: "50px !important" }}>
              <Stack
                spacing={0}
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    width: "40%",
                    borderBottom: "1px solid",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {data.userData.signature ? (
                    <Avatar
                      src={data.userData.signature}
                      sx={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    ></Avatar>
                  ) : (
                    ""
                  )}

                  {data.userData.name}
                </Typography>

                <Typography>Signature over Printed Name</Typography>
                <Typography sx={{ fontWeight: 700 }}>
                  Date:{" "}
                  <span style={{ borderBottom: "1px solid" }}>
                    {dayjs().format("MM-DD-YYYY")}
                  </span>
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Paper>
        <CurrentLoan
          currentLoan={checkedState.currentLoan}
          name={data.userData.name}
          prevLoanData={data.loanData}
          includeAttachment={checkedState.attachments}
        />
        <ComakerDataFrom
          comakerCertification={checkedState.comakerCertification}
        />
        <LoanHistory
          loanHistory={checkedState.loanHistory}
          prevLoanData={data.prevLoanData}
          name={data.userData.name}
          includeAttachment={checkedState.attachments}
        />
      </Box>
    </>
  );
}

export default UserDataForm;
