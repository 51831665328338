import React, { useEffect, useState } from "react";
import { useGetCoMakerQuery } from "../../../app/api/users/usersApiSlice";
import CustomTable from "../../../components/tables/Table";
import { Paper, Typography } from "@mui/material";
import DottedButton from "../../../components/buttons/CoMakerManagement.js";
import { CSVDownload } from "react-csv";
import dayjs from "dayjs";
import { ModalData } from "../../../components/modals/Modal";
import { CustomSnackbar } from "../../../components/popover/Snackbar";
import SharedDialog from "../../../components/modals/SharedDialog";
import UpdateCoMaker from "./UpdateCoMaker";

export default function CoMakerList() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [snackBarColor, setSnackBarColor] = React.useState("");
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [modalValue, setModalValue] = React.useState("");
  const [dialogChoice, setDialogChoice] = React.useState("");
  const [coMakerData, setCoMakerData] = useState([]);
  const [allData, setAllData] = useState([]);
  const { data, isLoading, isError } = useGetCoMakerQuery("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [borrowerId, setBorrowerId] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [isDownload, setIsDownload] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseSnackbar = () => {
    setPopoverOpen(false);
  };

  useEffect(() => {
    let allDataArray = [];
    if (!isLoading) {
      if (Array.isArray(data)) {
        allDataArray = data;
      } else {
        allDataArray = Object.entries(data.entities).map(
          ([key, value]) => value
        );
      }
    }

    processTableData(allDataArray);
  }, [data, isLoading]);

  useEffect(() => {
    if (isDownload) {
      setIsDownload(false); // Reset isDownload after initiating the download
    }
  }, [isDownload]);
  const processTableData = (data) => {
    const allData = data.map((item) => {
      return {
        id: item.id,
        name: item.name.firstname + " " + item.name.lastname,
        address: item.name.address,
        signature: item.attachments.signature,
        comaker_id: item.attachments.comaker_id,
        image: item.attachments.image,
      };
    });

    const tableData = data.map((item) => {
      return {
        id: item.id,
        name: item.name.firstname + " " + item.name.lastname,
        address: item.name.address,
        signature: item.attachments.signature,
        comaker_id: item.attachments.comaker_id,
        image: item.attachments.image,
      };
    });
    setCoMakerData(tableData);
    setAllData(allData);
  };

  let options = {};
  options = {
    search: true,
    download: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    jumpToPage: true,
    responsive: "standard", // Options: "standard", "vertical", "simple"
    print: false,
    selectableRowsHeader: false,
    selectableRows: false,
    setRowProps: (row, dataIndex) => {
      if (dataIndex === 0) {
        return {
          className: "MuiDataTable-FirstRow",
          style: { display: "none !important" },
        };
      }
      return {};
    },
    customHeadRender: () => {
      return <th style={{ display: "none !important" }} />;
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      printCSV();
      //setIsDownload(false);
      return false; // Returning true will proceed with the default download behavior
    },
  };

  const title = "";
  const btnOptions = ["View", "Edit", "Delete"];
  const customBodyRenderFunction = (dataIndex, rowData) => {
    const data = rowData[dataIndex]; // Assuming rowData[dataIndex] gives the required row data
    if (data) {
      return (
        <DottedButton
          options={btnOptions}
          data={data}
          isDialogOpen={setIsDialogOpen}
          uid={data.id}
          id={data.id}
          loanStatus={data.status}
          setBorrowerId={setBorrowerId}
          setModalOpen={setModalOpen}
          setModalType={setModalType}
          setModalValue={setModalValue}
        />
      );
    } else {
      return null; // Return null or any fallback if data is not available
    }
  };

  const columns = [
    { name: "comaker ID", options: { display: false } },
    { name: "Name", options: { filterOptions: { fullWidth: true } } },
    { name: "Address" },

    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        //customBodyRenderLite: (dataIndex) =>
        //  customBodyRenderFunction(dataIndex, coMakerData),
      },
    },
  ];

  const sortColumn = {
    name: "Status",
    direction: "asc", // or 'desc'
  };

  const printCSV = () => {
    const csv = [];
    csv.push(["Comaker List"]);
    csv.push([
      "ID",
      "Comaker Name",
      "Address",
      "isComakerID",
      "isSignature",
      "isImage",
    ]);

    allData.forEach((item) => {
      csv.push([
        item.id,
        item.name,
        item.address,
        item.comaker_id ? "Yes" : "No",
        item.signature ? "Yes" : "No",
        item.image ? "Yes" : "No",
      ]);
    });
    setCsvData(csv);
    setIsDownload(true);
  };

  let content = "";

  content = (
    <>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <Typography variant="h4" sx={{ fontWeight: 700 }} gutterBottom>
          Co-Maker List
        </Typography>
        <CustomTable
          title={title}
          data={coMakerData}
          columns={columns}
          options={options}
          sortColumn={sortColumn}
        />
      </Paper>
      {isDownload && (
        <CSVDownload
          data={csvData}
          target="_blank"
          filename={`comaker-list-${dayjs().format("YYYY-MM-DD")}.csv`}
        />
      )}
      {modalOpen && (
        <ModalData
          openModal={modalOpen}
          bodyContent={
            <UpdateCoMaker
              onClose={handleCloseModal}
              setSnackBarColor={setSnackBarColor}
              setSnackBarMsg={setSnackBarMsg}
              setPopoverOpen={setPopoverOpen}
              operatorData={modalValue}
            />
          }
          closeModal={handleCloseModal}
          type="addOperator"
        />
      )}
      {popoverOpen && (
        <CustomSnackbar
          openSnackBar={popoverOpen}
          closeSnackBar={handleCloseSnackbar}
          messageData={snackBarMsg}
          colorVariant={snackBarColor}
        />
      )}
      {isDialogOpen && (
        <SharedDialog
          isOpen={isDialogOpen}
          isDialogOpen={setIsDialogOpen}
          title={"Remove Operator"}
          bodyContent={"Are you sure you want to remove this Operator?"}
          setDialogChoice={setDialogChoice}
        />
      )}
    </>
  );
  return content;
}
